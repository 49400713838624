<!-- 融资余额及到期 -->
<template>
	<div class="home">
		<dataplat-search ref="search" @submit="handleSubmit"></dataplat-search>
		<DownloadBtn :id="40" :type=1 @exportData="exportData" :style="{
        'background-color': 'rgba(241, 110, 113, 1)',
        'margin-bottom': '10px',
      }">
		</DownloadBtn>

		<div :style="{ position: 'relative' }" v-if="showChart">
			<span class="unit" :style="{ position: 'absolute', top: '-25px', right: '15px' }">币种：人民币</span>

			<dataplat-content :columns="tableColumns" :list="tableList" :total="total" @changePage="handleChangePage"
				@changePageSize="handleChangePageSize"></dataplat-content>
			<dataplat-chart :list="tableList"></dataplat-chart>
		</div>
		<div v-else>
			<span class="unit" :style="{ position: 'absolute', top: '-25px', right: '15px' }">币种：人民币</span>

			<dataplat-content :columns="tableColumns" :list="tableList" :total="total" @changePage="handleChangePage"
				@changePageSize="handleChangePageSize"></dataplat-content>
		</div>
	</div>
</template>

<script>
	import SearchPanel from "./components/issueBonds/SearchPanel";
	import ContentPanel from "@/components/ContentPanel";
	import ChartPanel from "./components/issueBonds/ChartPanel";
	import {
		post_enterprise_rz_rzye,
		post_enterprise_rz_rzdq,
	} from "@/api/houseEnterprise";
	import DownloadBtn from "@/components/DownloadBtn";
	import {
		downloadData,
		formatJson
	} from "@/utils/index";
	export default {
		name: "issueBonds",
		components: {
			"dataplat-search": SearchPanel,
			"dataplat-content": ContentPanel,
			"dataplat-chart": ChartPanel,
			DownloadBtn,
		},
		computed: {
			gpdm() {
				return this.$store.state.reHouseEnterprise.selectedCompantGpmc
			},
		},
		watch: {
			gpdm(newVal, oldVal) {
				this.$refs.search.handleSearch();
			},
		},
		data() {
			return {
				tableColumns: [],
				tableList: [],
				total: 0,
				showChart: false,
				searchForm: {},
			};
		},
		methods: {
			handleChangePage(page) {
				this.searchForm.current = page;
				this.handleSubmit(this.searchForm);
			},
			handleChangePageSize(page) {
				this.searchForm.size = page;
				this.handleSubmit(this.searchForm);
			},
			//下载
			exportData() {
				const params = this.searchForm;
				params.size = 1000000;
				params.current = 1;
				const type = params.lx;
				let requestType = {
					rzye: post_enterprise_rz_rzye,
					rzdq: post_enterprise_rz_rzdq,
				};
				this.showChart = type === "rzdq";
				let requestParam = Object.assign(params, {
					gpdm: this.gpdm, // 股票代码
				});
				requestParam = JSON.parse(JSON.stringify(requestParam));
				delete requestParam.lx;
				const rzyeColumns = [{
						title: "时间",
						width: 200,
						key: "sj",
						align: "center",
						ellipsis: true,
						tooltip: true,
					},
					// {
					//   title: "币种",
					//   width: 80,
					//   key: "bz",
					//   ellipsis: true,
					//   tooltip: true,

					//   align: "center",
					// },
					{
						title: "融资方式",
						ellipsis: true,
						tooltip: true,

						key: "rzfs",
						align: "center",
					},
					{
						title: "加权平均成本",
						key: "jqpjcb",
						ellipsis: true,
						tooltip: true,

						width: 130,
						align: "center",
					},
					{
						title: "余额(亿元)",
						width: 110,
						ellipsis: true,
						tooltip: true,
						key: "ye",
						align: "center",
						render: (h, params) => {
							return h(
								"span",
								params.row.ye ?
								isNaN(params.row.ye) ?
								params.row.ye :
								Number(params.row.ye).toFixed(2) :
								params.row.ye
							);
						},
					},
					{
						title: "实际利率",
						width: 100,
						ellipsis: true,
						tooltip: true,

						key: "sjll",
						align: "center",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjll ?
								isNaN(params.row.sjll) ?
								params.row.sjll :
								(Number(params.row.sjll) * 100).toFixed(2) + "%" :
								params.row.sjll
							);
						},
					},
					{
						title: "实际利率-上限",
						width: 130,
						// ellipsis: true,
						// tooltip: true,
						key: "sjllsx",
						align: "center",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjllsx ?
								isNaN(params.row.sjllsx) ?
								params.row.sjllsx :
								(Number(params.row.sjllsx) * 100).toFixed(2) + "%" :
								params.row.sjllsx
							);
						},
					},
					{
						title: "实际利率-下限",
						width: 130,
						ellipsis: true,
						tooltip: true,

						key: "sjllxx",
						align: "center",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjllxx ?
								isNaN(params.row.sjllxx) ?
								params.row.sjllxx :
								(Number(params.row.sjllxx) * 100).toFixed(2) + "%" :
								params.row.sjllxx
							);
						},
					},
					{
						title: "票面利率",
						width: 100,
						ellipsis: true,
						tooltip: true,

						key: "pmll",
						align: "center",
						render: (h, params) => {
							return h(
								"span",
								params.row.pmll ?
								isNaN(params.row.pmll) ?
								params.row.pmll :
								(Number(params.row.pmll) * 100).toFixed(2) + "%" :
								params.row.pmll
							);
						},
					},
					{
						title: "票面利率-上限",
						width: 130,
						ellipsis: true,
						tooltip: true,

						key: "pmllsx",
						align: "center",
						render: (h, params) => {
							return h(
								"span",
								params.row.pmllsx ?
								isNaN(params.row.pmllsx) ?
								params.row.pmllsx :
								(Number(params.row.pmllsx) * 100).toFixed(2) + "%" :
								params.row.pmllsx
							);
						},
					},
					{
						title: "票面利率-下限",
						key: "pmllxx",
						ellipsis: true,
						tooltip: true,

						align: "center",
						render: (h, params) => {
							return h(
								"span",
								params.row.pmllxx ?
								isNaN(params.row.pmllxx) ?
								params.row.pmllxx :
								(Number(params.row.pmllxx) * 100).toFixed(2) + "%" :
								params.row.pmllxx
							);
						},
					},
				];
				const rzdqColumns = [{
						title: "时间",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "sj",
					},
					// {
					//   title: "币种",
					//   align: "center",
					//   ellipsis: true,
					//   tooltip: true,

					//   key: "bz",
					// },
					{
						title: "融资方式",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "rzfs",
					},
					{
						title: "一年以内",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "ynyn",
						render: (h, params) => {
							return h(
								"span",
								params.row.ynyn ?
								isNaN(params.row.ynyn) ?
								params.row.ynyn :
								Number(params.row.ynyn).toFixed(2) :
								params.row.ynyn
							);
						},
					},
					{
						title: "1年至2年",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "ynzn",
						render: (h, params) => {
							return h(
								"span",
								params.row.ynzn ?
								isNaN(params.row.ynzn) ?
								params.row.ynzn :
								Number(params.row.ynzn).toFixed(2) :
								params.row.ynzn
							);
						},
					},
					{
						title: "2年至5年",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "enzn",
						render: (h, params) => {
							return h(
								"span",
								params.row.enzn ?
								isNaN(params.row.enzn) ?
								params.row.enzn :
								Number(params.row.enzn).toFixed(2) :
								params.row.enzn
							);
						},
					},
					{
						title: "5年以上",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "wnys",
						render: (h, params) => {
							return h(
								"span",
								params.row.wnys ?
								isNaN(params.row.wnys) ?
								params.row.wnys :
								Number(params.row.wnys).toFixed(2) :
								params.row.wnys
							);
						},
					},
					{
						title: "一年以上",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "ynys",
						render: (h, params) => {
							return h(
								"span",
								params.row.ynys ?
								isNaN(params.row.ynys) ?
								params.row.ynys :
								Number(params.row.ynys).toFixed(2) :
								params.row.ynys
							);
						},
					},
					{
						title: "合计",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "hj",
						render: (h, params) => {
							return h(
								"span",
								params.row.hj ?
								isNaN(params.row.hj) ?
								params.row.hj :
								Number(params.row.hj).toFixed(2) :
								params.row.hj
							);
						},
					},
				];
				let columns = {
					rzye: rzyeColumns,
					rzdq: rzdqColumns,
				};
				requestType[type](requestParam)
					.then((data) => {
						let filterVal = [];
						let tHeader = [];
						columns[type].forEach((item) => {
							tHeader.push(item.title);
							filterVal.push(item.key);
						});
						const resData = formatJson(filterVal, data.records);
						downloadData(tHeader, resData, "融资余额及到期");
					})
					.catch((e) => {

					});
			},
			handleSubmit(params) {
				this.searchForm = params;
				const type = params.lx;
				let requestType = {
					rzye: post_enterprise_rz_rzye,
					rzdq: post_enterprise_rz_rzdq,
				};
				this.showChart = type === "rzdq";
				let requestParam = Object.assign(params, {
					gpdm: this.gpdm, // 股票代码
				});
				requestParam = JSON.parse(JSON.stringify(requestParam));
				delete requestParam.lx;
				const rzyeColumns = [{
						title: "时间",
						width: 200,
						key: "sj",
						align: "center",
						ellipsis: true,
						tooltip: true,
					},
					// {
					//   title: "币种",
					//   width: 80,
					//   key: "bz",
					//   ellipsis: true,
					//   tooltip: true,
					//   align: "center",
					// },
					{
						title: "融资方式",
						ellipsis: true,
						tooltip: true,

						key: "rzfs",
						align: "center",
					},
					{
						title: "加权平均成本",
						key: "jqpjcb",
						ellipsis: true,
						tooltip: true,

						width: 130,
						align: "center",
					},
					{
						title: "余额(亿元)",
						width: 110,
						ellipsis: true,
						tooltip: true,

						key: "ye",
						align: "center",
						render: (h, params) => {
							return h(
								"span",
								params.row.ye ?
								isNaN(params.row.ye) ?
								params.row.ye :
								Number(params.row.ye).toFixed(2) :
								params.row.ye
							);
						},
					},
					{
						title: "实际利率",
						width: 100,
						ellipsis: true,
						tooltip: true,

						key: "sjll",
						align: "center",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjll ?
								isNaN(params.row.sjll) ?
								params.row.sjll :
								(Number(params.row.sjll) * 100).toFixed(2) + "%" :
								params.row.sjll
							);
						},
					},
					{
						title: "实际利率-上限",
						width: 130,
						// ellipsis: true,
						tooltip: true,
						key: "sjllsx",
						align: "center",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjllsx ?
								isNaN(params.row.sjllsx) ?
								params.row.sjllsx :
								(Number(params.row.sjllsx) * 100).toFixed(2) + "%" :
								params.row.sjllsx
							);
						},
					},
					{
						title: "实际利率-下限",
						width: 130,
						ellipsis: true,
						tooltip: true,
						key: "sjllxx",
						align: "center",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjllxx ?
								isNaN(params.row.sjllxx) ?
								params.row.sjllxx :
								(Number(params.row.sjllxx) * 100).toFixed(2) + "%" :
								params.row.sjllxx
							);
						},
					},
					{
						title: "票面利率",
						width: 100,
						ellipsis: true,
						tooltip: true,

						key: "pmll",
						align: "center",
						render: (h, params) => {
							return h(
								"span",
								params.row.pmll ?
								isNaN(params.row.pmll) ?
								params.row.pmll :
								(Number(params.row.pmll) * 100).toFixed(2) + "%" :
								params.row.pmll
							);
						},
					},
					{
						title: "票面利率-上限",
						width: 130,
						ellipsis: true,
						tooltip: true,

						key: "pmllsx",
						align: "center",
						render: (h, params) => {
							return h(
								"span",
								params.row.pmllsx ?
								isNaN(params.row.pmllsx) ?
								params.row.pmllsx :
								(Number(params.row.pmllsx) * 100).toFixed(2) + "%" :
								params.row.pmllsx
							);
						},
					},
					{
						title: "票面利率-下限",
						width: 130,
						key: "pmllxx",
						ellipsis: true,
						tooltip: true,

						align: "center",

						render: (h, params) => {
							return h(
								"span",
								params.row.pmllxx ?
								isNaN(params.row.pmllxx) ?
								params.row.pmllxx :
								(Number(params.row.pmllxx) * 100).toFixed(2) + "%" :
								params.row.pmllxx
							);
						},
					},
				];
				const rzdqColumns = [{
						title: "时间",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "sj",
					},
					// {
					//   title: "币种",
					//   align: "center",
					//   ellipsis: true,
					//   tooltip: true,

					//   key: "bz",
					// },
					{
						title: "融资方式",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "rzfs",
					},
					{
						title: "一年以内",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "ynyn",
						render: (h, params) => {
							return h(
								"span",
								params.row.ynyn ?
								isNaN(params.row.ynyn) ?
								params.row.ynyn :
								Number(params.row.ynyn).toFixed(2) :
								params.row.ynyn
							);
						},
					},
					{
						title: "1年至2年",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "ynzn",
						render: (h, params) => {
							return h(
								"span",
								params.row.ynzn ?
								isNaN(params.row.ynzn) ?
								params.row.ynzn :
								Number(params.row.ynzn).toFixed(2) :
								params.row.ynzn
							);
						},
					},
					{
						title: "2年至5年",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "enzn",
						render: (h, params) => {
							return h(
								"span",
								params.row.enzn ?
								isNaN(params.row.enzn) ?
								params.row.enzn :
								Number(params.row.enzn).toFixed(2) :
								params.row.enzn
							);
						},
					},
					{
						title: "5年以上",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "wnys",
						render: (h, params) => {
							return h(
								"span",
								params.row.wnys ?
								isNaN(params.row.wnys) ?
								params.row.wnys :
								Number(params.row.wnys).toFixed(2) :
								params.row.wnys
							);
						},
					},
					{
						title: "一年以上",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "ynys",
						render: (h, params) => {
							return h(
								"span",
								params.row.ynys ?
								isNaN(params.row.ynys) ?
								params.row.ynys :
								Number(params.row.ynys).toFixed(2) :
								params.row.ynys
							);
						},
					},
					{
						title: "合计",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "hj",
						render: (h, params) => {
							return h(
								"span",
								params.row.hj ?
								isNaN(params.row.hj) ?
								params.row.hj :
								Number(params.row.hj).toFixed(2) :
								params.row.hj
							);
						},
					},
				];
				let columns = {
					rzye: rzyeColumns,
					rzdq: rzdqColumns,
				};
				requestType[type](requestParam)
					.then((data) => {
						this.tableColumns = columns[type];
						this.total = data.total;
						this.tableList = data.records;

					})
					.catch((e) => {

					});
			},
		},
	};
</script>
