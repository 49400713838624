const tagBoxMixin = {
	methods: {
		handleChangeOption(name, type, formItem, value) {
			let data = [];
			let choosedType = 'string';
			let timeStr = ''
			switch (type) {
				case 'string':
					data = [value];
					choosedType = 'string';
					break;
				case 'date':
					if (value[0].length === 0 && value[1].length === 0) {
						this.handleDelete(formItem);
						return;
					}
					data = [`${value[0]} ${value[1] ? `至 ${value[1]}` : ''}`];

					// if (value[0] && !value[1]) {
					// 	timeStr = `>= ${value[0]}`
					// } else if (!value[0] && value[1]) {
					// 	timeStr = `<=${value[1]}`
					// } else if (value[0] && value[1]) {
					// 	timeStr = `${value[0]}至${value[1]}`
					// }
					// data = [`${timeStr}`];

					choosedType = 'date';
					break;
				case 'list':
					choosedType = 'list';
					data = value;
					break;
				case 'radio':
					choosedType = 'radio';
					data = value;
					break;
				default:
					choosedType = 'string';
					data = value;
			}
			if (value && value.length !== 0) {
				const selectedList = Object.assign({}, this.selectedList);
				selectedList[formItem] = {
					title: name,
					choosedType,
					type,
					formItem,
					data
				}
				this.selectedList = selectedList;
			} else {
				this.handleDelete(formItem);
			}
		},
		handleDelete(type) {
			let selectedList = Object.assign({}, this.selectedList);
			delete selectedList[type]
			this.selectedList = selectedList;
			this.searchForm[type] = [];
		},
		handleClose(index, item) {
			const type = item.formItem;
			const {
				choosedType,
				data
			} = item;
			const formData = this.searchForm[type];
			if (choosedType === 'list' && data.length > 1) {
				let selectedList = Object.assign({}, this.selectedList);
				data.splice(index, 1);
				formData.splice(index, 1)
				selectedList[type].data = data;
				this.selectedList = selectedList;
				this.searchForm[type] = formData;
				return;
			}
			if (choosedType === 'radio') {
				// this.$Message.warning(`${item.title}必须选择`);
				this.$msg.error({
					text: `${item.title}必须选择`
				});

				return;
			}
			let selectedList = Object.assign({}, this.selectedList);
			selectedList[type] = null;
			this.selectedList = selectedList;
			if (choosedType === 'string') {
				this.searchForm[type] = '';
			} else {
				this.searchForm[type] = [];
			}
		}
	}
}

export default tagBoxMixin;
