<template>
	<div class="home">
		<dataplat-search ref="search" @submit="handleSubmit"></dataplat-search>
		<DownloadBtn :id="40" type=1 :style="{
        position: 'relative',
        left: 0,
        'margin-bottom': '20px',
        'background-color': 'rgba(241, 110, 113, 1)',
      }" @exportData="exportData"></DownloadBtn>
		<dataplat-content :columns="tableColumns" :list="tableList" :total="total" @changePage="handleChangePage"
			@changePageSize="handleChangePageSize" @handleViewBond='handleViewBond'></dataplat-content>
	</div>
</template>

<script>
	import SearchPanel from "./components/domesticDebt/SearchPanel";
	import ContentPanel from "@/components/ContentPanel";
	import {
		post_enterprise_rz_jnz
	} from "@/api/houseEnterprise";
	import DownloadBtn from "@/components/DownloadBtn";
	import {
		downloadData,
		formatJson
	} from "@/utils/index";
	export default {
		name: "domesticDebt",
		components: {
			"dataplat-search": SearchPanel,
			"dataplat-content": ContentPanel,
			DownloadBtn,
		},
		computed: {
			gpdm() {
				return this.$store.state.reHouseEnterprise.selectedCompantGpmc
			},
		},
		watch: {
			gpdm(newVal, oldVal) {
				this.$refs.search.handleSearch();
			},
		},
		data() {
			return {
				tableColumns: [],
				tableList: [],
				total: 0,
				searchForm: {},
			};
		},
		methods: {
			handleChangePage(page) {
				this.searchForm.current = page;
				this.handleSubmit(this.searchForm);
			},
			handleChangePageSize(page) {
				this.searchForm.size = page;
				this.handleSubmit(this.searchForm);
			},
			exportData() {
				const params = this.searchForm;
				params.size = 1000000;
				params.current = 1;
				let requestParam = Object.assign(params, {
					gpdm: this.gpdm, // 股票代码
				});
				const columns = [{
						title: "债券简称",
						align: "center",
						key: "zqjc",
						ellipsis: true,
						tooltip: true,
						slot: 'zqjc',
						width: 200,
						// render: (h, params) => {
						//   return h("div", [
						//     h(
						//       "a",
						//       {
						//         on: {
						//           click: () => this.handleViewBond(params.row.id),
						//         },
						//       },
						//       params.row.zqjc
						//     ),
						//   ]);
						// },
					},
					{
						title: "债券代码",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "zqdm",
						render: (h, params) => {
							return h(
								"a", {
									attrs: {
										class: "btn-detail",
										title: params.row.zqdm,
									},
									on: {
										click: () => this.handleViewBond(params.row.id),
										// click: () => {

										//   let path = this.tab == 'tab1' ? `/Insight/financialMarket/financialProduct/Bond/domesticDetail?tab=1` : `/Insight/financialMarket/financialProduct/Bond/domesticDetail?tab=3`;
										//   // this.$router.push({
										//   //     path: path,
										//   //     query: {
										//   //         id: params.row.id
										//   //     }
										//   // });
										//   let routeData = this.$router.resolve({
										//     path: path,
										//     query: {
										//       id: params.row.id
										//     }
										//   });
										//   window.open(routeData.href, '_blank')
										// }
									},
								},
								params.row.zqdm
							);
						},
					},
					{
						title: "发行日期",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "fxrq",
					},
					{
						title: "到期日期",
						align: "center",
						ellipsis: true,
						tooltip: true,
						key: "dqrq",
					},
					{
						title: "起息日期",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "qxrq",
					},
					{
						title: "计划发行总额(亿元)",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "jhfxze",
						render: (h, params) => {
							return h(
								"span",
								params.row.jhfxze ?
								isNaN(params.row.jhfxze) ?
								params.row.jhfxze :
								Number(params.row.jhfxze).toFixed(2) :
								params.row.jhfxze
							);
						},
					},
					{
						title: "实际发行总额(亿元)",
						align: "center",
						key: "sjfxze",
						ellipsis: true,
						tooltip: true,
						render: (h, params) => {
							return h(
								"span",
								params.row.sjfxze ?
								isNaN(params.row.sjfxze) ?
								params.row.sjfxze :
								Number(params.row.sjfxze).toFixed(2) :
								params.row.sjfxze
							);
						},
					},
					{
						title: "票面利率(%)",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "pmll",
						render: (h, params) => {
							return h(
								"span",
								params.row.pmll ?
								isNaN(params.row.pmll) ?
								params.row.pmll :
								(Number(params.row.pmll)).toFixed(2) + '%' :
								params.row.pmll
							);
						},
					},
					{
						title: "债券类型",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "zqlx",
					},
					{
						title: "发行人/受托机构",
						align: "center",
						key: "stjg",
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.gpdm),
										},
									},
									params.row.stjg
								),
							]);
						},
					},
					{
						title: "原始权益人",
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "ysqyr",
					},



					{
						title: "债券期限",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "zqqx",
					},



					{
						title: "预期收益率 (%)",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "yqsyl",
						render: (h, params) => {
							return h(
								"span",
								params.row.yqsyl ?
								isNaN(params.row.yqsyl) ?
								params.row.yqsyl :
								(Number(params.row.yqsyl)).toFixed(2) + '%' :
								params.row.yqsyl
							);
						},
					},
				];
				post_enterprise_rz_jnz(requestParam)
					.then((data) => {
						let filterVal = [];
						let tHeader = [];
						columns.forEach((item) => {
							tHeader.push(item.title);
							filterVal.push(item.key);
						});
						const resData = formatJson(filterVal, data.records);
						downloadData(tHeader, resData, "境内债");
					})
					.catch((e) => {

					});
			},
			handleSubmit(params) {
				this.searchForm = params;
				let requestParam = Object.assign(params, {
					gpdm: this.gpdm, // 股票代码
				});
				const columns = [{
						title: "债券简称",
						align: "center",
						key: "zqjc",
						ellipsis: true,
						tooltip: true,
						slot: 'zqjc',
						width: 200,
						// render: (h, params) => {
						//   return h("div", [
						//     h(
						//       "a",
						//       {
						//         on: {
						//           click: () => this.handleViewBond(params.row.id),
						//         },
						//       },
						//       params.row.zqjc
						//     ),
						//   ]);
						// },
					},
					{
						title: "债券代码",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "zqdm",
						render: (h, params) => {
							return h(
								"a", {
									attrs: {
										class: "btn-detail",
										title: params.row.zqdm,
									},
									on: {
										click: () => this.handleViewBond(params.row.id),
										// click: () => {

										//   let path = this.tab == 'tab1' ? `/Insight/financialMarket/financialProduct/Bond/domesticDetail?tab=1` : `/Insight/financialMarket/financialProduct/Bond/domesticDetail?tab=3`;
										//   // this.$router.push({
										//   //     path: path,
										//   //     query: {
										//   //         id: params.row.id
										//   //     }
										//   // });
										//   let routeData = this.$router.resolve({
										//     path: path,
										//     query: {
										//       id: params.row.id
										//     }
										//   });
										//   window.open(routeData.href, '_blank')
										// }
									},
								},
								params.row.zqdm
							);
						},
					},
					{
						title: "发行日期",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "fxrq",
					},
					{
						title: "到期日期",
						align: "center",
						ellipsis: true,
						tooltip: true,
						key: "dqrq",
					},
					{
						title: "起息日期",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "qxrq",
					},
					{
						title: "计划发行总额(亿元)",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "jhfxze",
						render: (h, params) => {
							return h(
								"span",
								params.row.jhfxze ?
								isNaN(params.row.jhfxze) ?
								params.row.jhfxze :
								Number(params.row.jhfxze).toFixed(2) :
								params.row.jhfxze
							);
						},
					},
					{
						title: "实际发行总额(亿元)",
						align: "center",
						key: "sjfxze",
						ellipsis: true,
						tooltip: true,
						render: (h, params) => {
							return h(
								"span",
								params.row.sjfxze ?
								isNaN(params.row.sjfxze) ?
								params.row.sjfxze :
								Number(params.row.sjfxze).toFixed(2) :
								params.row.sjfxze
							);
						},
					},
					{
						title: "票面利率(%)",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "pmll",
						render: (h, params) => {
							return h(
								"span",
								params.row.pmll ?
								isNaN(params.row.pmll) ?
								params.row.pmll :
								(Number(params.row.pmll)).toFixed(2) + '%' :
								params.row.pmll
							);
						},
					},
					{
						title: "债券类型",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "zqlx",
					},
					{
						title: "发行人/受托机构",
						align: "center",
						key: "stjg",
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.gpdm),
										},
									},
									params.row.stjg
								),
							]);
						},
					},
					{
						title: "原始权益人",
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "ysqyr",
					},



					{
						title: "债券期限",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "zqqx",
					},



					{
						title: "预期收益率 (%)",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "yqsyl",
						render: (h, params) => {
							return h(
								"span",
								params.row.yqsyl ?
								isNaN(params.row.yqsyl) ?
								params.row.yqsyl :
								(Number(params.row.yqsyl)).toFixed(2) + '%' :
								params.row.yqsyl
							);
						},
					},
				];
				post_enterprise_rz_jnz(requestParam)
					.then((data) => {
						this.tableColumns = columns;
						this.total = data.total;
						this.tableList = data.records || [];
					})
					.catch((e) => {

					});
			},
			handleViewInfo(id) {
				this.$router.push({
					path: `/Insight/enterprise/enterpriseHome/reHouseEnterprise/information`,
					query: {
						code: id,
					},
				});
			},
			handleViewBond(id) {
				let routeUrl = this.$router.resolve({
					path: `/Insight/financialMarket/financialProduct/Bond/domesticDetail?tab=1`,
					query: {
						id: id,
					},
				});
				window.open(routeUrl.href, "_blank");
			},
		},
	};
</script>
