<template>
	<div class="download-bar">
		<div class="download-table" @click="exportData">{{title}}</div>
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: "downloadBtn",
		props: {
			title: {
				type: String,
				default: "下载表格",
			},
			id: {
				type: Number,
				required: true,
			},
			type: {
				type: Number,
				required: true,
			},
		},
		methods: {
			exportData() {
				this.$btnlog(this.id);
				this.$btnSysLog(this.id,this.type)
				this.$emit("exportData");
			},
		},
	};
</script>

<style lang="scss" scoped>
	.download-bar {
		width: 80px;
		height: 25px;
		display: flex;
		justify-content: space-between;
		border-radius: 5px;
	}
	.download-table {
		width: 80px;
		height: 25px;
		font-size: 12px;
		color: #fff;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
