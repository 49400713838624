const url = {
    enterprise_rz_hztj: '/service-insight/enterprise_rz/hztj', // 查询汇总统计列表
    enterprise_rz_rzdq: '/service-insight/enterprise_rz/rzdq', // 查询融资到期
    enterprise_rz_rzye: '/service-insight/enterprise_rz/rzye', // 查询融资余额
    enterprise_rz_fxzqpg: '/service-insight/enterprise_rz/fxzqpg', // 查询发行债券-配股
    enterprise_rz_fxzqsf: '/service-insight/enterprise_rz/fxzqsf', // 查询发行债券-首发
    enterprise_rz_fxzqzf: '/service-insight/enterprise_rz/fxzqzf', // 查询发行债券-增发
    enterprise_rz_jnz: '/service-insight/enterprise_rz/jnz', // 查询境内债列表
    enterprise_rz_jwz: '/service-insight/enterprise_rz/jwz', // 查询境外债列表
    enterprise_rz_jdjqt: '/service-insight/enterprise_rz/jdjqt', // 查询借贷其它列表

    enterprise_dim_fxcz: '/service-insight/enterprise_dim/fxcz', // 查询发行筹资多选框
    enterprise_dim_jnz: '/service-insight/enterprise_dim/jnz', // 查询境内债多选框
    enterprise_dim_jwz: '/service-insight/enterprise_dim/jwz', // 查询境外债多选框
    enterprise_dim_jdjqt: '/service-insight/enterprise_dim/jdjqt', // 查询借贷其它债多选框
    enterprise_dim_bglx: '/service-insight/enterprise_dim/bglx', //报告类型
    enterprise_dim_bglxbhjb: '/service-insight/enterprise_dim/bglxbhjb', //报告类型,不含季报
    enterprise_dim_nbdfx: '/service-insight/enterprise_dim/nbdfx', //年报大分类维度

    enterprise_dim_zqlxzdh: '/service-insight/enterprise_dim/zqlxzdh', // 境内债债券类型(中地会-父级)
    enterprise_dim_zqlx: '/service-insight/enterprise_dim/zqlx', // 境内债债券类型(原始-子级[传父级id])
    enterprise_dim_qtrzfs: '/service-insight/enterprise_dim/qtrzfs', // 借贷及其他融资方式(类型(1.中地会，2.原始))
    enterprise_dim_jwzrzfs: '/service-insight/enterprise_dim/jwzrzfs', //境外债融资方式(类型(1.中地会，2.原始))
    finance_dim_rzfs: '/service-insight/finance_dim/rzfs', //境外债 融资方式    

    enterprise_dim_gglx: '/service-insight/enterprise_dim/gglx', //公告类型

    enterprise_qygg_gg: '/service-insight/enterprise_qygg/gg', //公告
    enterprise_qygg_nbwj: '/service-insight/enterprise_qygg/nbwj', //年报文件
    enterprise_jbsgList: '/service-insight/enterprise/jbsgList', //兼并收购列表
    enterprise_jbsg: '/service-insight/enterprise/jbsg', //兼并收购详情
    enterprise_xsqkList: '/service-insight/enterprise/xsqkList', //销售情况
    enterprise_tjkj: '/service-insight/enterprise/tjkj', //统计口径

    enterprise_tdcb_xztdcb: '/service-insight/enterprise_tdcb/xztdcb', //新土地储备
    enterprise_tdcb_ztdcb: '/service-insight/enterprise_tdcb/ztdcb', //总土地储备
    enterprise_tdcb_tdlb: '/service-insight/enterprise_tdcb/tdlb', //土地列表
    enterprise_tdcb_tdtjsj: '/service-insight/enterprise_tdcb/tdtjsj', //土地统计

    enterprise_gsxx: '/service-insight/enterprise/gsxx', //工商信息详情
    enterprise_gdxxList: '/service-insight/enterprise/gdxxList', //股东信息列表
    enterprise_dwtzList: '/service-insight/enterprise/dwtzList', //对外投资列表

    land_dim_landUse: '/service-insight/land_dim/landUse', //土地用途维度
    land_dim_sellWay: '/service-insight/land_dim/sellWay', //出让方式维度

    enterprise_cwzb: '/service-insight/enterprise/cwzb', //财务指标选择(主要财务指标)
    enterprise_lrb: '/service-insight/enterprise/lrb', //财务指标选择(利润表)
    enterprise_xjllb: '/service-insight/enterprise/xjllb', //财务指标选择(现金流量表)
    enterprise_zcfzb: '/service-insight/enterprise/zcfzb', //财务指标选择(资产负债表)
    enterprise_ccbb: '/service-insight/enterprise/ccbb', //财务报表搜索
    enterprise_zyywgcList: '/service-insight/enterprise/zyywgcList', //财务（主营业务构成）
    enterprise_zyywgcPie: '/service-insight/enterprise/zyywgcPie', //财务（主营业务构成 图表）   
    zyywgcPage : '/service-insight/enterprise/zyywgcPage',
    
    enterprise_dim_dimClassify: '/service-insight/enterprise_dim/dimClassify', //企业分类 （房企 银行 上下游）
    enterprise_gsxxType: '/service-insight/enterprise/gsxxType', //企业类型
    enterprise_save: '/service-insight/land_follow/save', //企业关注

}
export default url
