<template>
  <div class="home">
    <Card>
      <Form :model="searchForm" :label-width="120" inline>
        <FormItem label="融资类型">
          <Select
            v-model="searchForm.rzlx"
            clearable
            @on-clear="
              (value) =>
                this.handleChangeOption('融资类型', 'string', 'rzlx', [])
            "
            style="width: 140px"
            @on-change="
              (value) =>
                this.handleChangeOption('融资类型', 'string', 'rzlx', value)
            "
          >
            <Option
              v-for="item in lxBigList"
              :value="item.name"
              :key="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <!-- <FormItem label="融资方式">
          <Select v-model="searchForm.rzfs"
                  style="width:140px"
                  @on-change="(value) => this.handleChangeOption('融资方式', 'string', 'rzfs', value)">
            <Option v-for="item in lxSmallList"
                    :value="item.name"
                    :key="item.id">{{ item.name }}</Option>
          </Select>
                </FormItem>-->
        <FormItem label="公告日期">
          <DateRange
            v-model="searchForm.ggrq"
            @change="
              (value) =>
                this.handleChangeOption('公告日期', 'date', 'ggrq', value)
            "
          ></DateRange>
        </FormItem>
        <FormItem label="融资日期">
          <DateRange
            v-model="searchForm.rzrq"
            @change="
              (value) =>
                this.handleChangeOption('融资日期', 'date', 'rzrq', value)
            "
          ></DateRange>
        </FormItem>
        <FormItem>
          <Button class="searchBtnLog" data-id="1" type="primary" @click="handleSearch">查询</Button>
        </FormItem>
      </Form>
    </Card>
    <div class="tagbox">
      <template v-if="Object.keys(selectedList).length !== 0">
        已选条件：
        <template v-for="item in selectedList">
          <span v-if="item !== null" :key="item.formItem">
            <Tag
              v-for="(name, index) in item.data"
              :key="name"
              type="border"
              closable
              @on-close="() => handleClose(index, item)"
            >
              <span v-if="item">{{ item.title }}:</span>
              <span>{{ name }}</span>
            </Tag>
          </span>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { get_enterprise_dim_qtrzfs } from "@/api/houseEnterprise";
import tagBoxMixin from "@/views/share/tagBoxMixin";
import dateHandleMixin from "@/views/share/dateHandleMixin";
import { checkDate } from "@/utils/index";
export default {
  name: "SearchPanel",
  components: {},
  mixins: [tagBoxMixin, dateHandleMixin],
  data() {
    return {
      searchForm: {
        rzlx: "", // 融资方式(中地会)
        rzfs: "", // 融资方式
        ggrq: ["", ""], // 公告日期
        rzrq: ["", ""], // 融资日期
        size: 10,
        current: 1,
        rq: "", // 日期
      },
      lxBigList: [],
      lxSmallList: [],
      selectedList: {}, //已选条件
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    clearData() {
      Object.assign(this.$data, this.$options.data());
    },
    init() {
      this.getSelectList(1);
      this.getSelectList(2);
    },
    getSelectList(type) {
      get_enterprise_dim_qtrzfs({ lx: type })
        .then((data) => {
          if (type === 1) {
            this.lxBigList = data;
          } else {
            this.lxSmallList = data;
          }
          setTimeout(() => {
            this.handleSearch();
          }, 1000);
        })
        .catch((e) => {

        });
    },
    handleSearch() {
      const { ggrq, rzrq } = this.searchForm;
      if (!checkDate(ggrq[0], ggrq[1]) || !checkDate(rzrq[0], rzrq[1])) {
                  this.$msg.error({ type: "remind", code: 4015 });

        return;
      }
      let searchForm = this.handleDateRule(
        ggrq,
        "ggrq",
        "公告日期",
        this.searchForm
      );
      searchForm = this.handleDateRule(rzrq, "rzrq", "融资日期", searchForm);
      if (searchForm === null) {
        return;
      }
      this.$emit("submit", searchForm);
    },
  },
};
</script>
