<template>
  <div class="home">
    <Card>
      <Form :model="searchForm" :label-width="120" :rules="ruleValidate" inline>
        <FormItem label="数据类型" prop="lx">
          <RadioGroup
            v-model="searchForm.lx"
            @on-change="
              (data) => this.handleChangeValue('数据类型', 'radio', 'lx', data)
            "
          >
            <Radio
              :label="item.id"
              v-for="item in radioList.lx"
              :key="item.id"
              >{{ item.name }}</Radio
            >
          </RadioGroup>
        </FormItem>
        <FormItem label="报告类型" prop="bglx">
          <RadioGroup
            v-model="searchForm.bglx"
            @on-change="
              (data) =>
                this.handleChangeValue('报告类型', 'radio', 'bglx', data)
            "
          >
            <Radio
              :label="item.id"
              v-for="item in radioList.bglx"
              :key="item.id"
              >{{ item.name }}</Radio
            >
          </RadioGroup>
        </FormItem>
        <FormItem label="报告期">
          <!-- <Select
                        v-model="searchForm.rqgs"
                        style="width:80px"
                        @on-change="(value) => {this.handleChangeCycle('周期', 'string', 'rqgs', value)}"
                    >
                        <Option
                            v-for="item in zqList"
                            :value="item.id"
                            :key="item.id"
                        >{{ item.name }}</Option>
                    </Select>&nbsp;-->
          <DateRange
            v-model="searchForm.rq"
            :dateType="dateType"
            ref="DateRange"
            @change="
              (value) => this.handleChangeOption('报告期', 'date', 'rq', value)
            "
          ></DateRange>
        </FormItem>
        <FormItem>
          <Button class="searchBtnLog" data-id="1" type="primary" @click="handleSearch">查询</Button>
        </FormItem>
      </Form>
    </Card>
    <div class="tagbox">
      <template v-if="Object.keys(selectedList).length !== 0">
        已选条件：
        <template v-for="item in selectedList">
          <span v-if="item !== null" :key="item.formItem">
            <Tag
              v-for="(name, index) in item.data"
              :key="name"
              type="border"
              closable
              @on-close="() => handleClose(index, item)"
            >
              <span v-if="item">{{ item.title }}:</span>
              <span>{{ name }}</span>
            </Tag>
          </span>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { get_enterprise_dim_bglxbhjb } from "@/api/houseEnterprise";
import tagBoxMixin from "@/views/share/tagBoxMixin";
import dateHandleMixin from "@/views/share/dateHandleMixin";
import { checkDate } from "@/utils/index";
export default {
  name: "SearchPanel",
  components: {},
  mixins: [tagBoxMixin, dateHandleMixin],
  mounted() {
    this.init();
    setTimeout(()=>{
      this.handleSearch()
    },2000)
  },
  data() {
    return {
      searchForm: {
        bglx: "2", // 报告类型
        lx: "rzye", // 数据类型
        size: 10,
        current: 1,
        rq: ["2016", "2020"], // 日期
        rqgs: "2", // 日期格式(1.月,2.年)
      },
      zqList: [
        {
          id: "2",
          name: "年度",
        },
        {
          id: "3",
          name: "半年度",
        },
      ],
      dateType: "year",
      radioList: {
        lx: [
          {
            id: "rzye",
            name: "融资余额",
          },
          {
            id: "rzdq",
            name: "到期情况",
          },
        ],
        bglx: [
          {
            id: "2",
            name: "年度",
          },
          {
            id: "3",
            name: "半年度",
          },
        ],
      },
      selectedList: {}, //已选条件
      ruleValidate: {
        lx: [{ required: true, message: "", trigger: "change" }],
        bglx: [{ required: true, message: " ", trigger: "change" }],
      },
    };
  },
  methods: {
    init() {
      this.handleChangeOption("报告期", "date", "rq", ["2016", "2020"]);
      this.getRadioList();
        // this.handleSearch();
    },
    clearData() {
      Object.assign(this.$data, this.$options.data());
    },
    getRadioList() {
      this.handleChangeValue("数据类型", "radio", "lx", "rzye");
      this.handleChangeValue("报告类型", "radio", "bglx", "2");
      //   get_enterprise_dim_bglxbhjb().then(data => {
      //     this.radioList.bglx = data;
      //     this.searchForm.bglx = data[0].id;
      //     this.handleChangeValue('数据类型', 'radio', 'lx', 'rzye');
      //     this.handleChangeValue('报告类型', 'radio', 'bglx', data[0].id);
      //     this.handleSearch()
      //   }).catch(e => {

      //   });
    },
    handleChangeValue(name, type, formItem, value) {
      const checkedValue = value;
      const radioList = this.radioList[formItem];
      let list = [];
      for (let i = 0, len = radioList.length; i < len; i++) {
        if (radioList[i].id === checkedValue) {
          list.push(radioList[i].name);
          break;
        }
      }
      this.handleChangeOption(name, type, formItem, list);
    },
    handleChangeCycle(name, type, formItem, value) {
      this.searchForm[formItem] = value;
      if (value === "2") {
        this.dateType = "year";
      } else {
        this.dateType = "year";
      }
      this.searchForm.rq = ["", ""];
      this.$refs.DateRange.clearAll();
      delete this.selectedList.rq;
    },
    handleSearch() {
      const { rq } = this.searchForm;
      if (
        !checkDate(rq[0], rq[1]) ||
        rq[0] == "" ||
        rq[1] == "" ||
        rq.length == 0
      ) {
        this.$msg.error({ type: "remind", code: 4015 });

        return;
      }
      let searchForm = this.handleDateRule(rq, "rq", "报告期", this.searchForm);
      if (searchForm === null) {
        return;
      }
      searchForm.rq[0] += "-12-31";
      searchForm.rq[1] += "-12-31";
      this.$emit("submit", searchForm);
    },
  },
};
</script>
