import { post, get } from "@/utils/http";
import url from "./url_houseEnterprise";

// 查询汇总统计列表
export function post_enterprise_rz_hztj(params) {
    return post({ url: url.enterprise_rz_hztj, params })
}
// 查询融资到期
export function post_enterprise_rz_rzdq(params) {
    return post({ url: url.enterprise_rz_rzdq, params })
}
// 查询融资余额
export function post_enterprise_rz_rzye(params) {
    return post({ url: url.enterprise_rz_rzye, params })
}

// 查询发行债券-配股
export function post_enterprise_rz_fxzqpg(params) {
    return post({ url: url.enterprise_rz_fxzqpg, params })
}
// 查询发行债券-首发
export function post_enterprise_rz_fxzqsf(params) {
    return post({ url: url.enterprise_rz_fxzqsf, params })
}
// 查询发行债券-增发
export function post_enterprise_rz_fxzqzf(params) {
    return post({ url: url.enterprise_rz_fxzqzf, params })
}

// 查询境内债列表
export function post_enterprise_rz_jnz(params) {
    return post({ url: url.enterprise_rz_jnz, params })
}
// 查询境外债列表
export function post_enterprise_rz_jwz(params) {
    return post({ url: url.enterprise_rz_jwz, params })
}
// 查询借贷其它列表
export function post_enterprise_rz_jdjqt(params) {
    return post({ url: url.enterprise_rz_jdjqt, params })
}


// 查询发行筹资多选框
export function get_enterprise_dim_fxcz(params) {
    return get({ url: url.enterprise_dim_fxcz, params })
}
// 查询境内债多选框
export function get_enterprise_dim_jnz(params) {
    return get({ url: url.enterprise_dim_jnz, params })
}
// 查询境外债多选框
export function get_enterprise_dim_jwz(params) {
    return get({ url: url.enterprise_dim_jwz, params })
}
// 查询借贷其它债多选框
export function get_enterprise_dim_jdjqt(params) {
    return get({ url: url.enterprise_dim_jdjqt, params })
}
// 查询借贷其它债多选框
export function get_enterprise_dim_bglx(params) {
    return get({ url: url.enterprise_dim_bglx, params })
}
// 
export function get_enterprise_dim_bglxbhjb(params) {
    return get({ url: url.enterprise_dim_bglxbhjb, params })
}

// 债券类型(类型(1.中地会，2.原始))
export function get_enterprise_dim_zqlx(params) {
    return get({ url: url.enterprise_dim_zqlx, params })
}
export function get_enterprise_dim_zqlxzdh(params) {
    return get({ url: url.enterprise_dim_zqlxzdh, params })
}

// 借贷及其他融资方式(类型(1.中地会，2.原始))
export function get_enterprise_dim_qtrzfs(params) {
    return get({ url: url.enterprise_dim_qtrzfs, params })
}
// //境外债融资方式(类型(1.中地会，2.原始))
export function get_enterprise_dim_jwzrzfs(params) {
    return get({ url: url.enterprise_dim_jwzrzfs, params })
}
export function get_finance_dim_rzfs(params) {
    return get({ url: url.finance_dim_rzfs, params })
}

// 公告类型
export function get_enterprise_dim_gglx(params) {
    return get({ url: url.enterprise_dim_gglx, params })
}
// 公告类型
export function get_enterprise_dim_nbdfx(params) {
    return get({ url: url.enterprise_dim_nbdfx, params })
}

// 公告
export function post_enterprise_qygg_gg(params) {
    return post({ url: url.enterprise_qygg_gg, params })
}
//年报文件
export function post_enterprise_qygg_nbwj(params) {
    return post({ url: url.enterprise_qygg_nbwj, params })
}
//兼并收购列表
export function post_enterprise_jbsgList(params) {
    return post({ url: url.enterprise_jbsgList, params })
}
//兼并收购详情
export function get_enterprise_jbsg(params) {
    return get({ url: url.enterprise_jbsg, params })
}
//销售情况
export function post_enterprise_xsqkList(params) {
    return post({ url: url.enterprise_xsqkList, params })
}
//统计口径
export function post_enterprise_tjkj(params) {
    return post({ url: url.enterprise_tjkj, params })
}
//新土地储备
export function post_enterprise_tdcb_xztdcb(params) {
    return post({ url: url.enterprise_tdcb_xztdcb, params })
}
//总土地储备
export function post_enterprise_tdcb_ztdcb(params) {
    return post({ url: url.enterprise_tdcb_ztdcb, params })
}
//土地列表
export function post_enterprise_tdcb_tdlb(params) {
    return post({ url: url.enterprise_tdcb_tdlb, params })
}
//土地统计
export function post_enterprise_tdcb_tdtjsj(params) {
    return post({ url: url.enterprise_tdcb_tdtjsj, params })
}

//工商信息详情
export function post_enterprise_gsxx(params) {
    return post({ url: url.enterprise_gsxx, params })
}
//关注
export function post_enterprise_save(params) {
    return post({ url: url.enterprise_save, params })
}
//股东信息列表
export function post_enterprise_gdxxList(params) {
    return post({ url: url.enterprise_gdxxList, params })
}
//对外投资列表
export function post_enterprise_dwtzList(params) {
    return post({ url: url.enterprise_dwtzList, params })
}
//土地用途维度
export function get_land_dim_landUse(params) {
    return get({ url: url.land_dim_landUse, params })
}
 //出让方式维度
export function get_land_dim_sellWay(params) {
    return get({ url: url.land_dim_sellWay, params })
}

///财务指标选择(主要财务指标)
export function post_enterprise_cwzb(params) {
    return post({ url: url.enterprise_cwzb, params })
}
//财务指标选择(利润表)
export function post_enterprise_lrb(params) {
    return post({ url: url.enterprise_lrb, params })
}
//财务指标选择(现金流量表)
export function post_enterprise_xjllb(params) {
    return post({ url: url.enterprise_xjllb, params })
}
//财务指标选择(资产负债表)
export function post_enterprise_zcfzb(params) {
    return post({ url: url.enterprise_zcfzb, params })
}
 //财务报表搜索
export function post_enterprise_ccbb(params) {
    return post({ url: url.enterprise_ccbb, params })
}
//财务（主营业务构成）
export function post_enterprise_zyywgcList(params) {
    return post({ url: url.enterprise_zyywgcList, params })
}

//财务（主营业务构成分页）
export function zyywgcPage(params) {
    return post({ url: url.zyywgcPage, params })
}
//财务（主营业务构成 图表）
export function post_enterprise_zyywgcPie(params) {
    return post({ url: url.enterprise_zyywgcPie, params })
}
//财务（主营业务构成 图表）
export function get_enterprise_dim_dimClassify(params) {
    return get({ url: url.enterprise_dim_dimClassify, params })
}
//企业类型
export function post_enterprise_gsxxType(params) {
    return post({ url: url.enterprise_gsxxType, params })
}

// 获取币种汇率
export function getSshl(params) {
    return get({ url: '/service-insight/enterprise_dim/sshl', params })
}
