<template>
  <div class="right-box">
    <Tabs value="issueBonds" class="tab-box" ref="tabs">
      <!-- <TabPane label="融资统计" name="aggregateStatistics">
                <dataplat-aggregate></dataplat-aggregate>
            </TabPane>-->
      <TabPane label="融资余额及到期" name="issueBonds">
        <dataplat-issue-bonds ref="tabItem1"></dataplat-issue-bonds>
      </TabPane>
      <TabPane label="股权融资" name="balance">
        <dataplat-balance ref="tabItem2"></dataplat-balance>
      </TabPane>
      <TabPane label="境内债" name="domesticDebt">
        <dataplat-domestic-debt ref="tabItem3"></dataplat-domestic-debt>
      </TabPane>
      <TabPane label="境外债" name="offshoreDebt">
        <dataplat-offshore-debt ref="tabItem4"></dataplat-offshore-debt>
      </TabPane>
      <!-- <TabPane label="其他" name="otherLoans">
        <dataplat-other-loans ref="tabItem5"></dataplat-other-loans>
      </TabPane> -->
    </Tabs>
  </div>
</template>

<script>
// @ is an alias to /src
// import AggregateStatistics from "./components/AggregateStatistics"
import IssueBonds from "./components/IssueBonds";
import Balance from "./components/Balance";
import DomesticDebt from "./components/DomesticDebt";
import OffshoreDebt from "./components/OffshoreDebt";
import OtherLoans from "./components/OtherLoans";
import dataReports from "@/mixins/dataReports";

export default {
  name: "financing",
  mixins: [dataReports],
  components: {
    // 'dataplat-aggregate':AggregateStatistics,
    "dataplat-issue-bonds": IssueBonds,
    "dataplat-balance": Balance,
    "dataplat-domestic-debt": DomesticDebt,
    "dataplat-offshore-debt": OffshoreDebt,
    // "dataplat-other-loans": OtherLoans,
  },
  beforeMount() {
    this.$Notice.destroy();
  },
  mounted() {
    if (sessionStorage.getItem("tabSelect")) {
      this.$refs.tabs.activeKey = sessionStorage.getItem("tabSelect");
      sessionStorage.removeItem("tabSelect");
    }
  },
  methods: {
    replaceData() {
      for (let i = 0; i < 5; i++) {
        this.$refs[`tabItem${i + 1}`].$refs.search.clearData();
        this.$refs[`tabItem${i + 1}`].$refs.search.init();
      }
    },
  },
};
</script>
