<template>
	<div class="download-bar">
		<!-- <div class="download-table" @click="exportData">{{title}}</div> -->
		<Button size='large' type="info" @click="exportData">{{title}}</Button>
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: "downloadBtn",
		props: {
			title: {
				type: String,
				default: "下载数据",
			},
			id: {
				type: Number,
				required: true,
			},
			type: {
				type: Number,
				required: true,
			},
		},
		methods: {
			exportData() {
				// this.$btnlog(this.id);
				this.$btnSysLog(this.id,this.type)
				this.$emit("exportData");
			},
		},
	};
</script>

<style lang="scss" scoped>
	.download-bar {
		width: 90px;
		height: 35px;
		display: flex;
        // border: 1px solid;
		justify-content: space-between;
		border-radius: 5px;
	}
	.download-table {
		width: 90px;
		height: 35px;
		font-size: 16px;
		// color: #fff;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
