<template>
	<div class="home">
		<Card>
			<Form :model="searchForm" :label-width="120" inline>
				<!-- <FormItem label="债券全称">
          <Input
            name="username"
            v-model="searchForm.zqqc"
            placeholder="债券全称"
            @on-change="
              (event) =>
                this.handleChangeOption(
                  '债券全称',
                  'string',
                  'zqqc',
                  event.target.value
                )
            "
          />
        </FormItem> -->
				<FormItem label="到期日期">
					<DateRange v-model="searchForm.dqrq"
						@change="(value) =>this.handleChangeOption('到期日期', 'date', 'dqrq', value)"></DateRange>
				</FormItem>
				<FormItem label="起息日期">
					<DateRange v-model="searchForm.qxrq"
						@change="(value) =>this.handleChangeOption('起息日期', 'date', 'qxrq', value)"></DateRange>
				</FormItem>
				<FormItem label="发行日期">
					<DateRange v-model="searchForm.fxrq"
						@change="(value) =>this.handleChangeOption('发行日期', 'date', 'fxrq', value)"></DateRange>
				</FormItem>
				<br>
				<FormItem label="剩余期限">
					<Select v-model="searchForm.syqx" clearable
						@on-change="(value) =>this.handleChangeOption('剩余期限', 'string', 'syqx', value)"
						style="width: 200px; margin-right: 20px">
						<Option v-for="item in syqxList" :value="item.id" :key="item.name">{{ item.name }}</Option>
					</Select>
				</FormItem>
				<FormItem label="是否违约">
					<Select v-model="searchForm.sfwy" style="width: 140px" clearable
						@on-change="(value) =>this.handleChangeOption('是否违约', 'string', 'sfwy', value == 1 ?'是':value === 0 ? '否':'')">
						<Option v-for="item in wxList" :value="item.code" :key="item.name">{{ item.name }}</Option>
					</Select>
				</FormItem>
				<br>

				<FormItem label="债券简称"><Input name="username" v-model="searchForm.zqjc" placeholder="债券简称"
						@on-change="(event) =>this.handleChangeOption('债券简称','string','zqjc',event.target.value)" />
				</FormItem>
				<FormItem label="债券代码"><Input name="username" v-model="searchForm.zqdm" placeholder="债券代码"
						@on-change="(event) =>this.handleChangeOption('债券代码','string','zqdm',event.target.value)" />
				</FormItem>
				<FormItem label="债券类型">
					<Select v-model="searchForm.dfl" style="width: 140px" clearable
						@on-clear="() =>this.handleChangeOption('债券类型大分类', 'string', 'dfl', [])"
						@on-change="(value) =>this.handleChangeSelect('债券类型大分类','string','dfl',value)">
						<Option v-for="item in lxBigList" :value="item.name" :key="item.id">{{ item.name }}
						</Option>
					</Select>&nbsp;
					<Select v-model="searchForm.xfl" style="width: 140px" clearable
						@on-clear="() =>this.handleChangeOption('债券类型细分类', 'string', 'xfl', [])"
						@on-change="(value) =>this.handleChangeOption('债券类型细分类','string','xfl',value)">
						<Option v-for="item in lxSmallList" :value="item.name" :key="item.id">{{ item.name }}</Option>
					</Select>
				</FormItem>

				<br>
				<FormItem>
					<Button class="searchBtnLog" data-id="1" type="primary" @click="handleSearch">查询</Button>
				</FormItem>
			</Form>
		</Card>
		<div class="tagbox">
			<template v-if="Object.keys(selectedList).length !== 0">
				已选条件：
				<template v-for="item in selectedList">
					<span v-if="item !== null" :key="item.formItem">
						<Tag v-for="(name, index) in item.data" :key="name" type="border"
							@on-close="() => handleClose(index, item)">
							<template v-if="item"><span>{{ item.title }}:</span><span>{{ name }}</span></template>
						</Tag>
					</span>
				</template>
			</template>
		</div>
	</div>
</template>

<script>
	import {
		get_enterprise_dim_zqlxzdh,
		get_enterprise_dim_zqlx,
	} from "@/api/houseEnterprise";
	import {
		getSyqx
	} from "@/api/financial/debt";
	import {
		checkDate
	} from "@/utils/index";
	import tagBoxMixin from "@/views/share/tagBoxMixin";
	import dateHandleMixin from "@/views/share/dateHandleMixin";
	export default {
		name: "SearchPanel",
		components: {},
		mixins: [tagBoxMixin, dateHandleMixin],
		data() {
			return {
				searchForm: {
					dfl: "", // 债券类型大分类
					zqdm: "", // 债券代码
					zqjc: "", // 债券简称
					zqqc: "", // 债券全称
					xfl: "", // 债券类型细分类
					syqx: "", // 剩余期限
					sfwy: "", // 是否违约(是,否)
					qxrq: ["", ""], // 起息日期
					fxrq: ["", ""], // 发行日期
					dqrq: ["", ""], // 到期日期
					size: 10,
					current: 1,
					rq: "", // 日期
				},
				syqxList: [],
				lxBigList: [],
				lxSmallList: [],
				wxList: [{
						name: "是",
						code: 1,
					},
					{
						name: "否",
						code: 0
					},
				],
				selectedList: {}, //已选条件
			};
		},
		mounted() {
			this.init();
		},
		methods: {
			clearData() {
				Object.assign(this.$data, this.$options.data());
			},
			init() {
				this.getSelectListZqlxzdh();
				this.getSyqx();
				setTimeout(() => {
					this.handleSearch();
				}, 1000);
			},
			getSelectListZqlxzdh() {
				get_enterprise_dim_zqlxzdh()
					.then((data) => {
						this.lxBigList = data;
					})
					.catch((e) => {

					});
			},
			getSelectListZqlx(pid) {
				get_enterprise_dim_zqlx({
						pid: pid
					})
					.then((data) => {
						this.lxSmallList = data;
					})
					.catch((e) => {

					});
			},
			handleChangeSelect(name, type, formItem, value) {
				const {
					lxBigList
				} = this;
				let obj = lxBigList.filter((item) => {
					return item.name === value;
				});
				this.getSelectListZqlx(obj[0].id);
				this.handleChangeOption(name, type, formItem, value);
			},
			getSyqx() {
				getSyqx().then((res) => {
					this.syqxList = res;
				});
			},
			handleSearch() {
				const {
					fxrq,
					qxrq,
					dqrq
				} = this.searchForm;
				if (
					!checkDate(qxrq[0], qxrq[1]) ||
					!checkDate(fxrq[0], fxrq[1]) ||
					!checkDate(dqrq[0], dqrq[1])
				) {
					this.$msg.error({
						type: "remind",
						code: 4015
					});

					return;
				}
				let searchForm = this.handleDateRule(
					fxrq,
					"fxrq",
					"发行日期",
					this.searchForm
				);
				searchForm = this.handleDateRule(qxrq, "qxrq", "起息日期", searchForm);
				searchForm = this.handleDateRule(dqrq, "dqrq", "到期日期", searchForm);
				if (searchForm === null) {
					return;
				}

				//处理为空值的数组，使其变为空字符串
				for (const key in searchForm) {
					if (Array.isArray(searchForm[key])) {
						if (searchForm[key].length == 0) {
							searchForm[key] = ''
						}
					}
				}
				this.$emit("submit", searchForm);
			},
		},
	};
</script>
