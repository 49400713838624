<template>
	<div class>
		<dataplat-search ref="search" @submit="handleSubmit"></dataplat-search>
		<DownloadBtn :id="40" :type=1 :style="{
        position: 'relative',
        left: 0,
        'margin-bottom': '20px',
        'background-color': 'rgba(241, 110, 113, 1)',
      }" @exportData="exportData"></DownloadBtn>
		<dataplat-content :columns="tableColumns" :list="tableList" :total="total" @changePage="handleChangePage"
			@changePageSize="handleChangePageSize"></dataplat-content>
	</div>
</template>

<script>
	import SearchPanel from "./components/balance/SearchPanel";
	import ContentPanel from "@/components/ContentPanel";
	import {
		post_enterprise_rz_fxzqpg,
		post_enterprise_rz_fxzqsf,
		post_enterprise_rz_fxzqzf,
	} from "@/api/houseEnterprise";
	import {
		mapState,
		mapMutations
	} from "vuex";
	import {
		getOtherDetail,
		getAddDetail,
		getMatchDetail,
		getStartDetail,
	} from "@/api/financial/other";
	import DownloadBtn from "@/components/DownloadBtn";
	import {
		downloadData,
		formatJson
	} from "@/utils/index";
	export default {
		name: "issueBonds",
		components: {
			"dataplat-search": SearchPanel,
			"dataplat-content": ContentPanel,
			DownloadBtn,
		},
		computed: {
			gpdm() {
				return this.$store.state.reHouseEnterprise.selectedCompantGpmc
			},
			...mapState(["activeDetail"]),
		},
		watch: {
			gpdm(newVal, oldVal) {
				this.$refs.search.handleSearch();
			},
		},
		data() {
			return {
				tableColumns: [],
				tableList: [],
				total: 0,
				showChart: false,
				searchForm: {},
			};
		},
		methods: {
			...mapMutations(["changeState"]),
			handleChangePage(page) {
				this.searchForm.current = page;
				this.handleSubmit(this.searchForm);
			},
			handleChangePageSize(page) {
				this.searchForm.size = page;
				this.handleSubmit(this.searchForm);
			},
			exportData() {
				const params = this.searchForm;
				params.size = 1000000;
				params.current = 1;
				this.searchForm = params;
				const type = params.lx;
				let requestType = {
					fxzqpg: post_enterprise_rz_fxzqpg,
					fxzqsf: post_enterprise_rz_fxzqsf,
					fxzqzf: post_enterprise_rz_fxzqzf,
				};
				let requestParam = Object.assign(params, {
					gpdm: this.gpdm, // 股票代码
				});
				requestParam = JSON.parse(JSON.stringify(requestParam));
				delete requestParam.lx;
				const fxzqsfColumns = [{
						title: "证券代码",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "zqdm",
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.id),
										},
									},
									params.row.zqdm
								),
							]);
						},
					},
					{
						title: "证券简称",
						align: "center",
						key: "zqjc",
						ellipsis: true,
						tooltip: true,
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.id),
										},
									},
									params.row.zqjc
								),
							]);
						},
					},
					{
						title: "股票类别",
						align: "center",
						ellipsis: true,
						tooltip: true,
						key: "gplb",
					},
					{
						title: "总发行数量",
						align: "center",
						ellipsis: true,
						tooltip: true,
						key: "zfxsl",
						render: (h, params) => {
							return h(
								"span",
								params.row.zfxsl ?
								isNaN(params.row.zfxsl) ?
								params.row.zfxsl :
								Number(params.row.zfxsl).toFixed(2) :
								params.row.zfxsl
							);
						},
					},
					{
						title: "每股面值",
						renderHeader: (h, index) => {
							return h("div", [h("div", "每股面值"), h("div", "(元)")]);
						},
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "mgmz",
						render: (h, params) => {
							return h(
								"span",
								params.row.mgmz ?
								isNaN(params.row.mgmz) ?
								params.row.mgmz :
								Number(params.row.mgmz).toFixed(2) :
								params.row.mgmz
							);
						},
					},
					{
						title: "发行价格",
						ellipsis: true,
						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "发行价格"), h("div", "(元)")]);
						},
						align: "center",
						key: "fxjg",
						render: (h, params) => {
							return h(
								"span",
								params.row.fxjg ?
								isNaN(params.row.fxjg) ?
								params.row.fxjg :
								Number(params.row.fxjg).toFixed(2) :
								params.row.fxjg
							);
						},
					},
					{
						title: "发行方式",
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "fxfs",
					},
					{
						title: "实际募资总额",
						ellipsis: true,
						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "实际募资总额"), h("div", "(万元)")]);
						},
						align: "center",
						key: "sjmzze",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjmzze ?
								isNaN(params.row.sjmzze) ?
								params.row.sjmzze :
								Number(params.row.sjmzze).toFixed(2) :
								params.row.sjmzze
							);
						},
					},
					{
						title: "实际募资净额",
						ellipsis: true,
						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "实际募资净额"), h("div", "(万元)")]);
						},
						align: "center",
						key: "sjmzje",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjmzje ?
								isNaN(params.row.sjmzje) ?
								params.row.sjmzje :
								Number(params.row.sjmzje).toFixed(2) :
								params.row.sjmzje
							);
						},
					},
					{
						title: "发行日期",
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "swfxrq",
					},
					{
						title: "上市公告日期",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "ssggrq",
					},
					{
						title: "详细",
						key: "action",
						align: "center",
						ellipsis: true,
						tooltip: true,

						width: 120,
						fixed: "right",
						render: (h, params) => {
							return h("div", [
								h(
									"Button", {
										on: {
											click: () => this.handleViewMore(params.row.id, 3),
										},
									},
									"详细信息"
								),
							]);
						},
					},
				];

				const fxzqzfColumns = [{
						title: "证券代码",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "zqdm",
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.id),
										},
									},
									params.row.zqdm
								),
							]);
						},
					},
					{
						title: "证券简称",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "zqjc",
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.id),
										},
									},
									params.row.zqjc
								),
							]);
						},
					},
					{
						title: "股票类别",
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "gplb",
					},
					{
						title: "增发代码",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "zfdm",
					},
					{
						title: "增发简称",
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "zfjc",
					},
					{
						title: "发行价格",
						ellipsis: true,
						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "发行价格"), h("div", "(元)")]);
						},
						align: "center",
						key: "fxjg",
						render: (h, params) => {
							return h(
								"span",
								params.row.fxjg ?
								isNaN(params.row.fxjg) ?
								params.row.fxjg :
								Number(params.row.fxjg).toFixed(2) :
								params.row.fxjg
							);
						},
					},
					{
						title: "总发行数量",
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "zfxsl",
						render: (h, params) => {
							return h(
								"span",
								params.row.zfxsl ?
								isNaN(params.row.zfxsl) ?
								params.row.zfxsl :
								Number(params.row.zfxsl).toFixed(2) :
								params.row.zfxsl
							);
						},
					},
					{
						title: "每股面值",
						ellipsis: true,
						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "每股面值"), h("div", "(元)")]);
						},
						align: "center",
						key: "mgmz",
						render: (h, params) => {
							return h(
								"span",
								params.row.mgmz ?
								isNaN(params.row.mgmz) ?
								params.row.mgmz :
								Number(params.row.mgmz).toFixed(2) :
								params.row.mgmz
							);
						},
					},
					{
						title: "发行日期",
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "wsfxrq",
					},
					{
						title: "实际募资总额",
						ellipsis: true,
						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "实际募资总额"), h("div", "(万元)")]);
						},
						align: "center",
						key: "sjmzze",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjmzze ?
								isNaN(params.row.sjmzze) ?
								params.row.sjmzze :
								Number(params.row.sjmzze).toFixed(2) :
								params.row.sjmzze
							);
						},
					},
					{
						title: "实际募资净额",
						ellipsis: true,
						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "实际募资净额"), h("div", "(万元)")]);
						},
						align: "center",
						key: "sjmzje",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjmzje ?
								isNaN(params.row.sjmzje) ?
								params.row.sjmzze :
								Number(params.row.sjmzje).toFixed(2) :
								params.row.sjmzje
							);
						},
					},
					{
						title: "详细",
						ellipsis: true,
						tooltip: true,

						key: "action",
						width: 120,
						align: "center",
						fixed: "right",
						render: (h, params) => {
							return h("div", [
								h(
									"Button", {
										on: {
											click: () => this.handleViewMore(params.row.id, 2),
										},
									},
									"详细信息"
								),
							]);
						},
					},
				];
				const fxzqpgColumns = [{
						title: "证券代码",
						key: "zqdm",
						ellipsis: true,
						tooltip: true,

						align: "center",
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.id),
										},
									},
									params.row.zqdm
								),
							]);
						},
					},
					{
						title: "证券简称",
						key: "zqjc",
						ellipsis: true,
						tooltip: true,

						align: "center",
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.id),
										},
									},
									params.row.zqjc
								),
							]);
						},
					},
					{},
					{
						title: "股票类别",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "gplb",
					},
					{
						title: "配股比例",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "pgbl",
					},
					{
						title: "配股价格",
						renderHeader: (h, index) => {
							return h("div", [h("div", "配股价格"), h("div", "(元)")]);
						},
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "pgjg",
						render: (h, params) => {
							return h(
								"span",
								params.row.pgjg ?
								isNaN(params.row.pgjg) ?
								params.row.pgjg :
								Number(params.row.pgjg).toFixed(2) :
								params.row.pgjg
							);
						},
					},
					{
						title: "实际配股数量",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "sjpgsl",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjpgsl ?
								isNaN(params.row.sjpgsl) ?
								params.row.sjpgsl :
								Number(params.row.sjpgsl).toFixed(2) :
								params.row.sjpgsl
							);
						},
					},
					{
						title: "实际募资总额",
						renderHeader: (h, index) => {
							return h("div", [h("div", "实际募资总额"), h("div", "(万元)")]);
						},
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "sjmzze",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjmzze ?
								isNaN(params.row.sjmzze) ?
								params.row.sjmzze :
								Number(params.row.sjmzze).toFixed(2) :
								params.row.sjmzze
							);
						},
					},
					{
						title: "实际募资净额",
						renderHeader: (h, index) => {
							return h("div", [h("div", "实际募资净额"), h("div", "(万元)")]);
						},
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "sjmzje",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjmzje ?
								isNaN(params.row.sjmzje) ?
								params.row.sjmzje :
								Number(params.row.sjmzje).toFixed(2) :
								params.row.sjmzje
							);
						},
					},
					{
						title: "配股前总股本",
						renderHeader: (h, index) => {
							return h("div", [h("div", "配股前总股本"), h("div", "(元)")]);
						},
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "pgqzgb",
					},
					{
						title: "配股后总股本",
						renderHeader: (h, index) => {
							return h("div", [h("div", "配股后总股本"), h("div", "(元)")]);
						},
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "pghzgb",
					},
					{
						title: "配股上市日",
						align: "center",
						key: "pgssr",
						ellipsis: true,
						tooltip: true,
					},
					{
						title: "详细",
						key: "action",
						align: "center",
						ellipsis: true,
						tooltip: true,

						width: 120,
						fixed: "right",
						render: (h, params) => {
							return h("div", [
								h(
									"Button", {
										on: {
											click: () => this.handleViewMore(params.row.id),
										},
									},
									"详细信息"
								),
							]);
						},
					},
				];
				let columns = {
					fxzqpg: fxzqpgColumns,
					fxzqsf: fxzqsfColumns,
					fxzqzf: fxzqzfColumns,
				};
				requestType[type](requestParam)
					.then((data) => {
						let filterVal = [];
						let tHeader = [];
						columns[type].forEach((item) => {
							tHeader.push(item.title);
							filterVal.push(item.key);
						});
						const resData = formatJson(filterVal, data.records);
						downloadData(tHeader, resData, "股权融资");
					})
					.catch((e) => {

					});
			},
			handleSubmit(params) {
				this.searchForm = params;
				const type = params.lx;
				let requestType = {
					fxzqpg: post_enterprise_rz_fxzqpg,
					fxzqsf: post_enterprise_rz_fxzqsf,
					fxzqzf: post_enterprise_rz_fxzqzf,
				};
				let requestParam = Object.assign(params, {
					gpdm: this.gpdm, // 股票代码
				});
				requestParam = JSON.parse(JSON.stringify(requestParam));
				delete requestParam.lx;
				const fxzqsfColumns = [{
						title: "证券代码",
						align: "center",
						ellipsis: true,
						tooltip: true,
						key: "zqdm",
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.id),
										},
									},
									params.row.zqdm
								),
							]);
						},
					},
					{
						title: "证券简称",
						align: "center",
						key: "zqjc",
						ellipsis: true,
						tooltip: true,
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.id),
										},
									},
									params.row.zqjc
								),
							]);
						},
					},
					{
						title: "股票类别",
						align: "center",
						ellipsis: true,
						tooltip: true,
						key: "gplb",
					},
					{
						title: "总发行数量",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "zfxsl",
						render: (h, params) => {
							return h(
								"span",
								params.row.zfxsl ?
								isNaN(params.row.zfxsl) ?
								params.row.zfxsl :
								Number(params.row.zfxsl).toFixed(2) :
								params.row.zfxsl
							);
						},
					},
					{
						title: "每股面值",
						renderHeader: (h, index) => {
							return h("div", [h("div", "每股面值"), h("div", "(元)")]);
						},
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "mgmz",
						render: (h, params) => {
							return h(
								"span",
								params.row.mgmz ?
								isNaN(params.row.mgmz) ?
								params.row.mgmz :
								Number(params.row.mgmz).toFixed(2) :
								params.row.mgmz
							);
						},
					},
					{
						title: "发行价格",
						ellipsis: true,
						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "发行价格"), h("div", "(元)")]);
						},
						align: "center",
						key: "fxjg",
						render: (h, params) => {
							return h(
								"span",
								params.row.fxjg ?
								isNaN(params.row.fxjg) ?
								params.row.fxjg :
								Number(params.row.fxjg).toFixed(2) :
								params.row.fxjg
							);
						},
					},
					{
						title: "发行方式",
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "fxfs",
					},
					{
						title: "实际募资总额",
						ellipsis: true,
						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "实际募资总额"), h("div", "(万元)")]);
						},
						align: "center",
						key: "sjmzze",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjmzze ?
								isNaN(params.row.sjmzze) ?
								params.row.sjmzze :
								Number(params.row.sjmzze).toFixed(2) :
								params.row.sjmzze
							);
						},
					},
					{
						title: "实际募资净额",
						ellipsis: true,
						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "实际募资净额"), h("div", "(万元)")]);
						},
						align: "center",
						key: "sjmzje",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjmzje ?
								isNaN(params.row.sjmzje) ?
								params.row.sjmzje :
								Number(params.row.sjmzje).toFixed(2) :
								params.row.sjmzje
							);
						},
					},
					{
						title: "发行日期",
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "swfxrq",
					},
					{
						title: "上市公告日期",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "ssggrq",
					},
					{
						title: "详细",
						key: "action",
						align: "center",
						ellipsis: true,
						tooltip: true,

						width: 120,
						fixed: "right",
						render: (h, params) => {
							return h("div", [
								h(
									"Button", {
										on: {
											click: () => this.handleViewMore(params.row.id, 2),
										},
									},
									"详细信息"
								),
							]);
						},
					},
				];

				const fxzqzfColumns = [{
						title: "证券代码",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "zqdm",
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.id),
										},
									},
									params.row.zqdm
								),
							]);
						},
					},
					{
						title: "证券简称",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "zqjc",
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.id),
										},
									},
									params.row.zqjc
								),
							]);
						},
					},
					{
						title: "股票类别",
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "gplb",
					},
					{
						title: "增发代码",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "zfdm",
					},
					{
						title: "增发简称",
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "zfjc",
					},
					{
						title: "发行价格",
						ellipsis: true,
						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "发行价格"), h("div", "(元)")]);
						},
						align: "center",
						key: "fxjg",
						render: (h, params) => {
							return h(
								"span",
								params.row.fxjg ?
								isNaN(params.row.fxjg) ?
								params.row.fxjg :
								Number(params.row.fxjg).toFixed(2) :
								params.row.fxjg
							);
						},
					},
					{
						title: "总发行数量",
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "zfxsl",
						render: (h, params) => {
							return h(
								"span",
								params.row.zfxsl ?
								isNaN(params.row.zfxsl) ?
								params.row.zfxsl :
								Number(params.row.zfxsl).toFixed(2) :
								params.row.zfxsl
							);
						},
					},
					{
						title: "每股面值",
						ellipsis: true,
						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "每股面值"), h("div", "(元)")]);
						},
						align: "center",
						key: "mgmz",
						render: (h, params) => {
							return h(
								"span",
								params.row.zfxsl ?
								isNaN(params.row.mgmz) ?
								params.row.mgmz :
								Number(params.row.mgmz).toFixed(2) :
								params.row.mgmz
							);
						},
					},
					{
						title: "发行日期",
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "wsfxrq",
					},
					{
						title: "实际募资总额",
						ellipsis: true,
						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "实际募资总额"), h("div", "(万元)")]);
						},
						align: "center",
						key: "sjmzze",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjmzze ?
								isNaN(params.row.sjmzze) ?
								params.row.sjmzze :
								Number(params.row.sjmzze).toFixed(2) :
								params.row.sjmzze
							);
						},
					},
					{
						title: "实际募资净额",
						ellipsis: true,
						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "实际募资净额"), h("div", "(万元)")]);
						},
						align: "center",
						key: "sjmzje",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjmzze ?
								isNaN(params.row.sjmzje) ?
								params.row.sjmzje :
								Number(params.row.sjmzje).toFixed(2) :
								params.row.sjmzje
							);
						},
					},
					{
						title: "详细",
						ellipsis: true,
						tooltip: true,

						key: "action",
						width: 120,
						align: "center",
						fixed: "right",
						render: (h, params) => {
							return h("div", [
								h(
									"Button", {
										on: {
											click: () => this.handleViewMore(params.row.id, 1),
										},
									},
									"详细信息"
								),
							]);
						},
					},
				];
				const fxzqpgColumns = [{
						title: "证券代码",
						key: "zqdm",
						ellipsis: true,
						tooltip: true,

						align: "center",
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.id),
										},
									},
									params.row.zqdm
								),
							]);
						},
					},
					{
						title: "证券简称",
						key: "zqjc",
						ellipsis: true,
						tooltip: true,

						align: "center",
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.id),
										},
									},
									params.row.zqjc
								),
							]);
						},
					},
					{
						title: "股票类别",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "gplb",
					},
					{
						title: "配股比例",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "pgbl",
					},
					{
						title: "配股价格",
						renderHeader: (h, index) => {
							return h("div", [h("div", "配股价格"), h("div", "(元)")]);
						},
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "pgjg",
						render: (h, params) => {
							return h(
								"span",
								params.row.pgjg ?
								isNaN(params.row.pgjg) ?
								params.row.pgjg :
								Number(params.row.pgjg).toFixed(2) :
								params.row.pgjg
							);
						},
					},
					{
						title: "实际配股数量",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "sjpgsl",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjpgsl ?
								isNaN(params.row.sjpgsl) ?
								params.row.sjpgsl :
								Number(params.row.sjpgsl).toFixed(2) :
								params.row.sjpgsl
							);
						},
					},
					{
						title: "实际募资总额",
						renderHeader: (h, index) => {
							return h("div", [h("div", "实际募资总额"), h("div", "(万元)")]);
						},
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "sjmzze",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjmzze ?
								isNaN(params.row.sjmzze) ?
								params.row.sjmzze :
								Number(params.row.sjmzze).toFixed(2) :
								params.row.sjmzze
							);
						},
					},
					{
						title: "实际募资净额",
						renderHeader: (h, index) => {
							return h("div", [h("div", "实际募资净额"), h("div", "(万元)")]);
						},
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "sjmzje",
						render: (h, params) => {
							return h(
								"span",
								params.row.sjmzje ?
								isNaN(params.row.sjmzje) ?
								params.row.sjmzje :
								Number(params.row.sjmzje).toFixed(2) :
								params.row.sjmzje
							);
						},
					},
					{
						title: "配股前总股本",
						renderHeader: (h, index) => {
							return h("div", [h("div", "配股前总股本"), h("div", "(元)")]);
						},
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "pgqzgb",
					},
					{
						title: "配股后总股本",
						renderHeader: (h, index) => {
							return h("div", [h("div", "配股后总股本"), h("div", "(元)")]);
						},
						ellipsis: true,
						tooltip: true,

						align: "center",
						key: "pghzgb",
					},
					{
						title: "配股上市日",
						align: "center",
						key: "pgssr",
						ellipsis: true,
						tooltip: true,
					},
					{
						title: "详细",
						key: "action",
						align: "center",
						ellipsis: true,
						tooltip: true,

						width: 120,
						fixed: "right",
						render: (h, params) => {
							return h("div", [
								h(
									"Button", {
										on: {
											click: () => this.handleViewMore(params.row.id, 3),
										},
									},
									"详细信息"
								),
							]);
						},
					},
				];
				let columns = {
					fxzqpg: fxzqpgColumns,
					fxzqsf: fxzqsfColumns,
					fxzqzf: fxzqzfColumns,
				};
				requestType[type](requestParam)
					.then((data) => {
						this.tableColumns = columns[type];
						this.total = data.total;
						this.tableList = data.records || [];
					})
					.catch((e) => {

					});
			},
			handleViewMore(id, type = 0) {
				const api = {
					1: getAddDetail,
					0: getOtherDetail,
					2: getStartDetail,
					3: getMatchDetail,
				};
				api[type]({
					id: id,
				}).then((info) => {
					const title = {
						0: "",
						1: "增发",
						3: "配股",
						2: "新股",
					};
					info.gptitle = title[type];
					this.changeState({
						prop: "activeDetail",
						value: info,
					});
					localStorage.setItem("activeDetail", JSON.stringify(info));
					const path = {
						0: "/Insight/financialMarket/financialProduct/others/otherDetail",
						1: "/Insight/financialMarket/financialProduct/stockDetail",
						3: "/Insight/financialMarket/financialProduct/stockDetail",
						2: "/Insight/financialMarket/financialProduct/stockDetail",
					};
					let routeUrl = this.$router.resolve({
						path: path[type],
					});
					window.open(routeUrl.href, "_blank");
				});
			},
			handleViewInfo(id) {
				this.$router.push({
					path: `/Insight/enterprise/enterpriseHome/reHouseEnterprise/information`,
					query: {
						code: id,
					},
				});
			},
		},
	};
</script>
