<template>
  <div class="home">
    <Card>
      <Form :model="searchForm" :label-width="120" :rules="ruleValidate" inline>
        <FormItem label="类型" prop="lx">
          <RadioGroup
            v-model="searchForm.lx"
            @on-change="
              (data) => this.handleChangeValue('类型', 'radio', 'lx', data)
            "
          >
            <Radio
              :label="item.id"
              v-for="item in radioList.lx"
              :key="item.id"
              >{{ item.name }}</Radio
            >
          </RadioGroup>
        </FormItem>
        <FormItem label="发行日期">
          <DateRange
            v-model="searchForm.rq"
            @change="
              (value) => {
                this.handleChangeOption('发行日期', 'date', '', value);
                this.clg(value)
                }
            "
          ></DateRange>
        </FormItem>
        <FormItem>
          <Button class="searchBtnLog" data-id="1" type="primary" @click="handleSearch">查询</Button>
        </FormItem>
      </Form>
    </Card>
    <div class="tagbox">
      <template v-if="Object.keys(selectedList).length !== 0">
        已选条件：
        <template v-for="item in selectedList">
          <span v-if="item !== null" :key="item.formItem">
            <Tag
              v-for="(name, index) in item.data"
              :key="name"
              type="border"
              closable
              @on-close="() => handleClose(index, item)"
            >
              <span v-if="item">{{ item.title }}:</span>
              <span>{{ name }}</span>
            </Tag>
          </span>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import tagBoxMixin from "@/views/share/tagBoxMixin";
import dateHandleMixin from "@/views/share/dateHandleMixin";
import { checkDate } from "@/utils/index";
export default {
  name: "SearchPanel",
  components: {},
  mixins: [tagBoxMixin, dateHandleMixin],
  data() {
    return {
      searchForm: {
        lx: "fxzqsf", // 数据类型
        size: 10,
        current: 1,
        rq: ["1980-01-01",new Date().toLocaleDateString()], // 日期
      },
      radioList: {
        lx: [
          {
            id: "fxzqsf",
            name: "首发",
          },
          // {
          //   id: "fxzqzf",
          //   name: "增发",
          // },
          // {
          //   id: "fxzqpg",
          //   name: "配股",
          // }
          // ,
        ],
      },
      selectedList: {}, //已选条件
      ruleValidate: {
        lx: [{ required: true, message: "", trigger: "change" }],
      },
    };
  },
  mounted() {
    this.init();
    setTimeout(()=>{
      this.handleSearch()
    },2000)
  },
  methods: {
    clg(value){
    },
    clearData() {
      Object.assign(this.$data, this.$options.data());
    },
    init() {
      this.initRadioSelect();
      // this.handleSearch();
    },
    initRadioSelect() {
      this.handleChangeValue("类型", "radio", "lx", "fxzqsf");
    },
    handleChangeValue(name, type, formItem, value) {
      const checkedValue = value;
      const radioList = this.radioList[formItem];
      let list = [];
      for (let i = 0, len = radioList.length; i < len; i++) {
        if (radioList[i].id === checkedValue) {
          list.push(radioList[i].name);
          break;
        }
      }
      this.handleChangeOption(name, type, formItem, list);
    },
    handleSearch() {
      const { rq } = this.searchForm;
      if (!checkDate(rq[0], rq[1]) || rq[0] == '' || rq[1] == '') {
          this.$msg.error({ type: "remind", code: 4015 });

        return;
      }
      let searchForm = this.handleDateRule(
        rq,
        "rq",
        "发行日期",
        this.searchForm
      );
      if (searchForm === null) {
        return;
      }
      this.$emit("submit", searchForm);

    },
  },
};
</script>
