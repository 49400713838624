const dateHandleMixin = {
    methods: {
        handleDateRule(type, typeName, label, formData) {
            let searchForm;
            let notNull = false;
            let allHasValue = true;
            type.map(item => {
                if (item.length !== 0) {
                    notNull = true;
                } else {
                    allHasValue = false;
                }
            })
            const requestParam = JSON.parse(JSON.stringify(formData))
            searchForm = Object.assign(requestParam)
            if (!notNull) {
                searchForm = Object.assign(requestParam, {
                    [typeName]: null
                })
                return searchForm;
            } else if (!allHasValue) {

                this.$msg.error({
                    type: "remind",
                    code: 4021
                });


                return null;
            }
            return searchForm;
        }
    }
}

export default dateHandleMixin;