<template>
<div>
    <h4>融资到期情况</h4>
    <div class="home" id="chart_container" style="height: 460px">
    </div>
</div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'chartPanel',
    components: {},
    props: ['list'],
    mounted() {
        this.drawChart([{
            'product': 'name',
            '1年以内': 0,
            '1年以上': 0,
            '1年至两年': 0,
            '2年至5年': 0,
            '5年以上': 0
        }]);
    },
    watch: {
        list(newValue, oldValue) {
            let data = [];
            newValue.map(item => {
                data.push({
                    'product': item.sj,
                    '1年以内': item.ynyn,
                    '1年以上': item.ynys,
                    // '1年至两年': item.ynzn, 
                    // '2年至5年':item.enzn, 
                    // '5年以上':item.wnys
                })
            })
            this.drawChart(data)
        }
    },
    methods: {
        drawChart(chartData) {
            var myChart = this.$echarts.init(document.getElementById('chart_container'));
            var app = {};
            let option = null;
            option = {
                legend: {},
                tooltip: {},
                dataset: {
                    source: chartData
                },
                xAxis: {
                    type: 'category',
                    axisLine: {
                        lineStyle: {
                            color: '#F1F4F6',
                            width: 1, //这里是为了突出显示加上的
                        }
                    },
                    axisLabel: { // 坐标轴文本标签，详见axis.axisLabel
                        show: true,
                        rotate: 0,
                        margin: 8,
                        textStyle: { // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                            color: '#A0A4AA'
                        }
                    },
                },

                yAxis: {
                    axisLine: {
                        lineStyle: {
                            color: '#fff',
                            width: 1, //这里是为了突出显示加上的
                        }
                    },

                },
                series: [{
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: '#5B8FF9'
                            }
                        }
                    },
                    {
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: '#5AD8A6'
                            }
                        }
                    },
                    // {
                    //     type: 'bar',
                    //     itemStyle: {
                    //         normal: {
                    //             color:'#5D7092'
                    //         }
                    //     }                        
                    // },
                    // {
                    //     type: 'bar',
                    //     itemStyle: {
                    //         normal: {
                    //             color:'#F6BD16'
                    //         }
                    //     }                        
                    // },
                ]
            };
            myChart.setOption(option, true);
        }
    }
}
</script>
