import { post, get } from "@/utils/http";

//境内债查询
export function queryJnz(params) {
  return post({ url: "/service-insight/finance_jnz/queryZq", params });
}
//境内债详情
export function queryJnzById(params) {
  return get({ url: "/service-insight/finance_jnz/getByIdZq", params });
}

//项目进度查询
export function queryXmjd(params) {
  return post({ url: "/service-insight/finance_jnz/queryJyjd", params });
}
//项目进度详情
export function queryXmjdById(params) {
  return get({ url: "/service-insight/finance_jnz/getByIdJyjd", params });
}

export function xmjdSjly(params) {
  return get({ url: "/service-insight/finance_jnz/xmjdSjly", params });
}

export function xmjdXmzt(params) {
  return get({ url: "/service-insight/finance_jnz/xmjdXmzt", params });
}

export function xmjdZqpz(params) {
  return get({ url: "/service-insight/finance_jnz/xmjdZqpz", params });
}

export function xmjdHylx(params) {
  return get({ url: "/service-insight/finance_jnz/xmjdHylx", params });
}
// export function xmjdFxje(params) {
//     return get({ url: '/finance_jnz/xmjdFxje', params })
// }

//境外债
export function queryJwz(params) {
  return post({ url: "/service-insight/finance_jwz/query", params });
}
//境外债
export function getSyqx(params) {
  return get({ url: "/service-insight/finance_dim/syqx", params });
}
//境外债
export function getJnzhy(params) {
  return get({ url: "/service-insight/finance_dim/jnzhy", params });
}

//境外债详情
export function queryJwzById(params) {
  return get({ url: "/service-insight/finance_jwz/getById", params });
}

//境外债-债券类型
export function foreign_debt_type_options(params) {
  return get({
    url:
      "/service-insight/data-fin-fn-debt-info/data/foreign_debt_type_options",
    params,
  });
}
//境外债-行业
export function industry_options(params) {
  return get({
    url: "/service-insight/data-fin-fn-debt-info/data/industry_options",
    params,
  });
}
//境外债-查询
export function foreign_debt_page(params) {
  return post({
    url: "/service-insight/data-fin-fn-debt-info/data/foreign_debt_page",
    params,
  });
}
//境外债-详情
export function foreign_debt_details(params) {
  return get({
    url: "/service-insight/data-fin-fn-debt-info/data/foreign_debt_details",
    params,
  });
}
//可转债
export function queryKzz(params) {
  return post({ url: "/service-insight/finance_kzz/query", params });
}
//可转债详情
export function queryKzzById(params) {
  return get({ url: "/service-insight/finance_kzz/getById", params });
}
//债券类型（zdh）
export function zjlx_zdh(params) {
  return post({ url: "/service-insight/finance_jnz/zjlx_zdh", params });
}
//债券类型（原始）
export function zqlxy(params) {
  return get({ url: "/service-insight/finance_jnz/zqlxy", params });
}
//债券类型（zdh）
export function zqlx(params) {
  return get({ url: "/service-insight/enterprise_dim/zqlx", params });
}
//债券类型（原始）
export function zqlxzdh(params) {
  return get({ url: "/service-insight/enterprise_dim/zqlxzdh", params });
}
//融资类
export function jwzrzfs(params) {
  return get({ url: "/service-insight/enterprise_dim/jwzrzfs", params });
}
//融资类
export function rzfs(params) {
  return get({ url: "/service-insight/finance_dim/rzfs", params });
}
//融资类
export function fxje(params) {
  return get({ url: "/service-insight/finance_jnz/fxje", params });
}
//融资类
export function dq(params) {
  return get({ url: "/service-insight/finance_jnz/dq", params });
}
