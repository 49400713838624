import { post, get } from '@/utils/http'

export function getOtherData(params) {
    return post({ url: '/service-insight/finance_other/get_other_data', params })
}
export function getOtherDetail(params) {
    return get({ url: '/service-insight/finance_other/other_detail', params })
}
export function getAddDetail(params) {
    return get({ url: '/service-insight/finance_stock/add_detail', params })
}
export function getMatchDetail(params) {
    return get({ url: '/service-insight/finance_stock/match_detail', params })
}
export function getStartDetail(params) {
    return get({ url: '/service-insight/finance_stock/start_detail', params })
}
export function getDetail(params) {
    return get({ url: '/service-insight/finance_other/other_detail', params })
}
export function qtrz(params) {
    return get({ url: '/service-insight/finance_dim/qtrz', params })
}
//境外债
export function getQtrzfs(params) {
    return get({ url: '/service-insight/enterprise_dim/qtrzfs', params })
}