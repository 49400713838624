<template>
	<div class="home">
		<dataplat-search ref="search" @submit="handleSubmit"></dataplat-search>
		<DownloadBtn :id="40" :type=1 :style="{
        position: 'relative',
        left: 0,
        'margin-bottom': '20px',
        'background-color': 'rgba(241, 110, 113, 1)',
      }" @exportData="exportData"></DownloadBtn>
		<dataplat-content :columns="tableColumns" :list="tableList" :total="total" @changePage="handleChangePage"
			@changePageSize="handleChangePageSize"></dataplat-content>
	</div>
</template>

<script>
	import SearchPanel from "./components/otherLoans/SearchPanel";
	import ContentPanel from "@/components/ContentPanel";
	import {
		post_enterprise_rz_jdjqt
	} from "@/api/houseEnterprise";
	import {
		mapState,
		mapMutations
	} from "vuex";
	import {
		getDetail
	} from "@/api/financial/other";
	import DownloadBtn from "@/components/DownloadBtn";
	import {
		downloadData,
		formatJson
	} from "@/utils/index";
	export default {
		name: "domesticDebt",
		components: {
			"dataplat-search": SearchPanel,
			"dataplat-content": ContentPanel,
			DownloadBtn,
		},
		computed: {
			gpdm() {
				return this.$store.state.reHouseEnterprise.selectedCompantGpmc
			},
			...mapState(["activeDetail"]),
		},
		watch: {
			gpdm(newVal, oldVal) {
				this.$refs.search.handleSearch();
			},
		},
		data() {
			return {
				tableColumns: [],
				tableList: [],
				total: 0,
				searchForm: {},
			};
		},
		methods: {
			...mapMutations(["changeState"]),
			handleChangePage(page) {
				this.searchForm.current = page;
				this.handleSubmit(this.searchForm);
			},
			exportData() {
				let requestParam = Object.assign(this.searchForm, {
					gpdm: this.gpdm, // 股票代码
					size: 1000000,
					current: 1,
				});
				const columns = [{
						title: "企业简称",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "qyjc",
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.id),
										},
									},
									params.row.qyjc
								),
							]);
						},
					},
					{
						title: "股票代码",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "gpdm",
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.id),
										},
									},
									params.row.gpdm
								),
							]);
						},
					},
					{
						title: "融资方式",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "rzfs",
					},
					{
						title: "公告日期",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "ggrq",
					},
					{
						title: "实际融资日期",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "sjrzrq",
					},
					{
						title: "融资状态",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "rzzt",
					},
					{
						title: "融资类型",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "rzfszdh",
					},
					{
						title: "融资规模(亿元)",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "rzgmyy",
						render: (h, params) => {
							return h(
								"span",
								params.row.rzgmyy ?
								isNaN(params.row.rzgmyy) ?
								params.row.rzgmyy :
								Number(params.row.rzgmyy).toFixed(2) :
								params.row.rzgmyy
							);
						},
					},
					{
						title: "币种",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "bz",
					},
					{
						title: "融资利率",
						align: "center",
						ellipsis: true,
						tooltip: true,
						key: "rzll",
						render: (h, params) => {
							return h(
								"span",
								params.row.rzll ?
								isNaN(params.row.rzll) ?
								params.row.rzll :
								(Number(params.row.rzll) * 100).toFixed(2) + "%" :
								params.row.rzll
							);
						},
					},
					{
						title: "期限",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "qx",
					},
					{
						title: "实际融资人",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "sjrzr",
					},
					{
						title: "股权比例",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "gqbl",

						render: (h, params) => {
							return h(
								"span",
								params.row.gqbl ?
								isNaN(params.row.gqbl) ?
								params.row.gqbl :
								(Number(params.row.gqbl) * 100).toFixed(2) + "%" :
								params.row.gqbl
							);
						},
					},
					{
						title: "融资详情",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "action",
						width: 120,
						fixed: "right",
						render: (h, params) => {
							return h("div", [
								h(
									"Button", {
										on: {
											click: () => this.handleViewMore(params.row.id),
										},
									},
									"查看详细"
								),
							]);
						},
					},
				];
				post_enterprise_rz_jdjqt(requestParam)
					.then((data) => {
						let filterVal = [];
						let tHeader = [];
						columns.forEach((item) => {
							tHeader.push(item.title);
							filterVal.push(item.key);
						});
						const resData = formatJson(filterVal, data.records);
						downloadData(tHeader, resData, "其他");
					})
					.catch((e) => {

					});
			},
			handleChangePageSize(page) {
				this.searchForm.size = page;
				this.handleSubmit(this.searchForm);
			},
			handleSubmit(params) {
				this.searchForm = params;
				let requestParam = Object.assign(params, {
					gpdm: this.gpdm, // 股票代码
				});
				const columns = [{
						title: "企业简称",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "qyjc",
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.id),
										},
									},
									params.row.qyjc
								),
							]);
						},
					},
					{
						title: "股票代码",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "gpdm",
						render: (h, params) => {
							return h("div", [
								h(
									"a", {
										on: {
											click: () => this.handleViewInfo(params.row.id),
										},
									},
									params.row.gpdm
								),
							]);
						},
					},
					{
						title: "融资方式",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "rzfs",
					},
					{
						title: "公告日期",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "ggrq",
					},
					{
						title: "实际融资日期",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "sjrzrq",
					},
					{
						title: "融资状态",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "rzzt",
					},
					{
						title: "融资类型",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "rzfszdh",
					},
					{
						title: "融资规模(亿元)",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "rzgmyy",
						render: (h, params) => {
							return h(
								"span",
								params.row.rzgmyy ?
								isNaN(params.row.rzgmyy) ?
								params.row.rzgmyy :
								Number(params.row.rzgmyy).toFixed(2) :
								params.row.rzgmyy
							);
						},
					},
					{
						title: "币种",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "bz",
					},
					{
						title: "融资利率",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "rzll",
						render: (h, params) => {
							return h(
								"span",
								params.row.rzll ?
								isNaN(params.row.rzll) ?
								params.row.rzll :
								(Number(params.row.rzll) * 100).toFixed(2) + "%" :
								params.row.rzll
							);
						},
					},
					{
						title: "期限",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "qx",
					},
					{
						title: "实际融资人",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "sjrzr",
					},
					{
						title: "股权比例",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "gqbl",
						render: (h, params) => {
							return h(
								"span",
								params.row.gqbl ?
								isNaN(params.row.gqbl) ?
								params.row.gqbl :
								(Number(params.row.gqbl) * 100).toFixed(2) + "%" :
								params.row.gqbl
							);
						},
					},
					{
						title: "融资详情",
						align: "center",
						ellipsis: true,
						tooltip: true,

						key: "action",
						width: 120,
						fixed: "right",
						render: (h, params) => {
							return h("div", [
								h(
									"Button", {
										on: {
											click: () => this.handleViewMore(params.row.id),
										},
									},
									"查看详细"
								),
							]);
						},
					},
				];
				post_enterprise_rz_jdjqt(requestParam)
					.then((data) => {
						this.tableColumns = columns;
						this.total = data.total;
						this.tableList = data.records || [];
					})
					.catch((e) => {

					});
			},
			handleViewMore(id) {
				getDetail({
					id: id,
				}).then((info) => {
					this.changeState({
						prop: "activeDetail",
						value: info,
					});
					localStorage.setItem("activeDetail", JSON.stringify(info));
					let routeUrl = this.$router.resolve({
						path: `/Insight/financialMarket/financialProduct/others/otherDetail`,
					});
					window.open(routeUrl.href, "_blank");
				});
			},
			handleViewInfo(id) {
				this.$router.push({
					path: `/Insight/enterprise/enterpriseHome/reHouseEnterprise/information`,
					query: {
						code: id,
					},
				});
			},
		},
	};
</script>
