<template>
	<div>
		<Table border tooltip-theme="light" :columns="columns" :data="list" stripe class="table-box">
			<template slot-scope="{ row }" slot="zqjc">
				<Tooltip v-if="row.isHalfYear" content="债券半年内到期" theme="light" transfer>
					<span class="icon ban">半</span>
				</Tooltip>
				<Tooltip v-if="row.isWy == '1'" content="该债券已违约" theme="light" transfer>
					<span class="icon wei">违</span>
				</Tooltip>
				<a class="linkBtn" :title="row.zqjc" @click="geDetail(row)">
					{{row.zqjc}}
				</a>
			</template>
		</Table>
		<Page v-if="total !== 0" class="pagetion" :total="total" prev-text="上一页" next-text="下一页" show-elevator
			show-sizer @on-page-size-change="handleChangePageSize" @on-change="handleChangePage" />
	</div>
</template>
<script>
	export default {
		name: "contentPanel",
		props: ["columns", "list", "total"],
		data() {
			return {};
		},
		mounted() {
		},
		methods: {
			handleChangePage(page) {
				this.$emit("changePage", page);
			},
			handleChangePageSize(page) {
				this.$emit("changePageSize", page);
			},
			geDetail(row){
				this.$emit('handleViewBond',row.id)
			}
		},
	};
</script>
<style lang="scss" scoped>
	.icon {
		display: inline-block;
		width: 20px;
		height: 20px;
		font-size: 14px;
		text-align: center;
		line-height: 20px;
		color: white;
		margin: 0 3px;

		&.ban {
			background-color: #F59A23;
		}

		&.wei {
			background-color: #D9001B;
		}
	}

	.linkBtn {
		width: 120px;
		display: inline-block;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		position: relative;
		top: 7px;
	}
</style>
