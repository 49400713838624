<template>
  <div class="foreignDebt">
    <div class="topPart" style="height: 250px">
      <div class="form-item">
        <div class="inputBox">
          <div class="title">到期日期</div>
          <DatePicker
            type="daterange"
            :value="[params.dueDateStart, params.dueDateEnd]"
            placeholder="请输入起止时间"
            style="width: 250px"
            separator="至"
            @on-change="(date) => getDate(date, 'dueDate')"
          ></DatePicker>
        </div>
        <div class="inputBox">
          <div class="title">剩余期限</div>
          <Select v-model="params.RemainingTerm" style="width: 250px" clearable>
            <Option
              v-for="item in RemainingTermList"
              :value="item.value"
              :key="item.id"
              >{{ item.label }}</Option
            >
          </Select>
          <div
            class="backIcon"
            @mouseover="showHelpInfo = true"
            @mouseleave="showHelpInfo = false"
          >
            <Tooltip max-width="200" theme="light">
              <Icon type="ios-help-circle-outline" size="30" />
              <div slot="content" class="helpBox">
                <p>6个月按180天计算</p>
                <p>1年按365天计算</p>
              </div>
            </Tooltip>
          </div>
        </div>
        <div class="inputBox">
          <div class="title">融资日期</div>
          <DatePicker
            type="daterange"
            :value="[params.financialDateStart, params.financialDateEnd]"
            placeholder="请输入起止时间"
            style="width: 250px"
            separator="至"
            @on-change="(date) => getDate(date, 'financialDate')"
          ></DatePicker>
        </div>
      </div>
      <div class="form-item">
        <div class="inputBox">
          <div class="title">是否违约</div>
          <Select v-model="params.isDefault" style="width: 250px" clearable>
            <Option
              v-for="item in isDefaultList"
              :value="item.value"
              :key="item.id"
              >{{ item.label }}</Option
            >
          </Select>
        </div>
        <div class="inputBox">
          <div class="title">债券类型</div>
          <Select v-model="params.bondType" style="width: 250px" clearable>
            <Option
              v-for="item in bondTypeList"
              :value="item.bondType"
              :key="item.id"
              >{{ item.bondType }}</Option
            >
          </Select>
        </div>
        <div class="inputBox">
          <div class="title">行业</div>
          <Select
            v-model="params.zstIndustryCode"
            style="width: 250px"
            clearable
          >
            <Option
              v-for="item in zstIndustryCodeList"
              :value="item.zstIndustryCode"
              :key="item.id"
              >{{ item.zstIndustryName }}</Option
            >
          </Select>
        </div>
      </div>
      <div class="form-item">
        <div class="inputBox">
          <div class="title">实际融资人</div>
          <Input
            v-model="params.actualFinanciersFull"
            placeholder="请输入"
            style="width: 250px"
          />
        </div>
      </div>
      <!-- <div v-show="showHelpInfo" class="helpInfoBox">
        <p>6个月按180天计算</p>
        <p>1年按365天计算</p>
      </div> -->
      <Button
        class="searchBtnLog" data-id="1"
        type="info"
        style="width: 144px; display: block; margin: 0 auto"
        @click="getData(1, params.pageSize)"
        >查询</Button
      >
    </div>
    <div class="bottomPart">
      <div class="download">
        <DownloadDataBtn
          :style="{
            float: 'right',
          }"
          :id="40" :type="1"
          @exportData="exportData"
        ></DownloadDataBtn>
      </div>

      <Table
        border
        tooltip-theme="light"
        :loading="tableLoading"
        :columns="tableColumn"
        :data="tableData"
      >
        <template slot-scope="{ row }" slot="actualFinanciersFullSlot">
          <div class="actualFinanciersFullSlot">
            <div class="infoIcon">
              <Tooltip transfer content="6个月内到期" theme="light">
                <div v-if="row.isHalfYear" class="titleIcon">
                  <span>半</span>
                </div>
              </Tooltip>
              <Tooltip transfer content="已违约" theme="light">
                <div v-if="row.isDefault" class="warnIcon">
                  <span>违</span>
                </div>
              </Tooltip>
            </div>
            <div class="infoTitle">
              <p :title="row.actualFinanciersFull">
                {{ row.actualFinanciersFull }}
              </p>
            </div>
          </div>
        </template>
        <template slot-scope="{ row }" slot="action">
          <div class="diyDetail" @click="toDetail(row.id)">
            <span>详情</span>
          </div>
        </template>
      </Table>
      <div style="text-align: right; padding: 20px">
        <Page
          :current="params.current"
          :total="total"
          show-elevator
          show-sizer
          @on-change="pageChange"
          class-name="pageStyle"
          :page-size-opts="pageSizeOpts"
          @on-page-size-change="pageSizeChange"
          ref="page"
        />
      </div>
    </div>
    <div
      v-show="showTitleIconInfo"
      class="titleIconInfoBox"
      :style="{ top: iconY + 'px', left: iconX + 'px' }"
    >
      {{ titleIconInfoText }}
    </div>
    <div
      v-show="showTitleIconInfo"
      class="titleIconInfoBox"
      :style="{ top: iconY + 'px', left: iconX + 'px' }"
    >
      {{ titleIconInfoText }}
    </div>
  </div>
</template>

<script>
import DownloadDataBtn from "@/components/DownloadDataBtn";
import { downloadData, formatJson } from "@/utils/index";
import {
  queryJwz,
  foreign_debt_type_options,
  industry_options,
  foreign_debt_page,
} from "@/api/financial/debt";
import { getQy } from "@/api/public";
import dataReports from "@/mixins/dataReports";
import tagBoxMixin from "@/views/share/tagBoxMixin";
import { mapState } from "vuex";
import util from "../../../../utils/utils2";
export default {
  name: "foreignDebt",
  mixins: [dataReports, tagBoxMixin],
  components: {
    DownloadDataBtn,
  },
  data() {
    return {
      params: {
        current: 1,
        pageSize: 10,
        groupEnterpriseFull: "",
        groupStockCode: "",
        actualFinanciersFull: "",
        dueDateStart: "",
        dueDateEnd: "",
        RemainingTerm: "",
        financialDateStart: "",
        financialDateEnd: "",
        isDefault: "",
        bondType: "",
        zstIndustryCode: "",
      },
      typeGroup: [],
      tableLoading: false,
      checkAlltype: false,
      total: 0,
      isShowTypeChexk: false,
      zqlxzdhList: [],
      zqlxList: [],

      //期限列表
      RemainingTermList: [
        { id: 0, value: 1, label: "6个月以内" },
        { id: 1, value: 2, label: "6个月~1年" },
        { id: 2, value: 3, label: "1年~2年" },
        { id: 3, value: 4, label: "2年以上" },
      ],
      //是否违约
      isDefaultList: [
        { id: 0, value: 0, label: "否" },
        { id: 1, value: 1, label: "是" },
      ],
      //债券类型
      bondTypeList: [],
      //行业
      zstIndustryCodeList: [],
      //表格字段
      tableColumn: [
        {
          title: "实际融资人",
          align: "center",
          ellipsis: true,
          maxWidth: 500,
          width: 250,
          tooltip: true,
          slot: "actualFinanciersFullSlot",
          key: "actualFinanciersFull",
        },
        {
          title: "融资日期",
          align: "center",
          ellipsis: true,
          // width: 150,
          tooltip: true,
          key: "financialDate",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                params.row.financialDate
                  ? util.formatDateFmt(
                      new Date(params.row.financialDate),
                      "yyyy-MM-dd"
                    )
                  : "--"
              ),
            ]);
          },
        },
        {
          title: "到期日期",
          align: "center",
          ellipsis: true,
          // width: 150,
          tooltip: true,

          key: "dueDate",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                params.row.dueDate
                  ? util.formatDateFmt(
                      new Date(params.row.dueDate),
                      "yyyy-MM-dd"
                    )
                  : "--"
              ),
            ]);
          },
        },
        {
          title: "发行规模（亿元）",
          align: "center",
          ellipsis: true,
          // width: 150,
          tooltip: true,

          key: "distributionScale",
          render: (h, params) => {
            return h(
              "span",
              params.row.distributionScale
                ? params.row.distributionScale.toFixed(2)
                : "--"
            );
          },
        },
        {
          title: "票面利率(%)",
          align: "center",
          ellipsis: true,
          // width: 150,
          tooltip: true,

          key: "couponRate",
          render: (h, params) => {
            return h(
              "span",
              params.row.couponRate ? params.row.couponRate : "--"
            );
          },
        },
        {
          title: "币种",
          align: "center",
          ellipsis: true,
          // width: 150,
          tooltip: true,

          key: "distributionCurrency",
        },
        {
          title: "操作",
          align: "center",
          ellipsis: true,
          // width: 150,
          slot: "action",
          tooltip: true,

          key: "action",
        },
      ],
      //表格数据
      tableData: [],
      //分页页数
      pageSizeOpts: [10, 20, 50, 100],
      //展示
      showHelpInfo: false,
      //半、违规信息展示
      showTitleIconInfo: false,
      titleIconInfoText: "",
      //展示的行id
      showRowNum: "",
      iconX: "",
      iconY: "",
    };
  },
  computed: {
    typeText() {
      return this.typeGroup.join(",");
    },
    gpdm() {
      return this.$store.state.reHouseEnterprise.selectedCompantGpmc;
    },
  },
  watch: {
    gpdm(newVal, oldVal) {
      this.getData(1, this.params.pageSize);
    },
  },
  mounted() {
    // this.$parent.currentName = "OffshoreDebt";
    let params = sessionStorage.getItem("OffshoreDebt")
      ? JSON.parse(sessionStorage.getItem("OffshoreDebt"))
      : this.params;
    this.params = params;
    this.$nextTick(() => {
      this.$refs.page.currentPageSize = this.params.pageSize;
    });
    this.getData(params.current, params.pageSize);
    this.get_foreign_debt_type_options();
    this.get_industry_options();
  },
  methods: {
    getJwz(current, pageSize) {
      return new Promise((res, rej) => {
        // this.params.current = current;
        // this.params.pageSize = pageSize;
        let params = {
          current: current,
          pageSize: pageSize,
        };
        foreign_debt_page({ ...this.params, ...params })
          .then((data) => {
            res(data);
          })
          .catch((err) => {
            rej(err);
          });
      });
    },
    //查询获取境外债数据
    get_foreign_debt_page(current, pageSize) {
      return new Promise((resolve, reject) => {
        this.params.current = current;
        this.params.pageSize = pageSize;
        this.params.groupStockCode = this.gpdm;
        this.params.groupStockCodeEq = this.gpdm;
        foreign_debt_page(this.params)
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    //获取数据
    async getData(current, pageSize) {
      if (sessionStorage.getItem("OffshoreDebt")) {
        sessionStorage.removeItem("OffshoreDebt");
      }
      this.tableLoading = true;
      this.params.current = current;
      this.params.pageSize = pageSize;
      let data = await this.get_foreign_debt_page(current, pageSize);
      this.tableData = data.records;
      this.total = data.total;
      this.params.current = data.current;
      this.tableLoading = false;
    },
    //页码改变
    pageChange(current) {
      this.params.current = current;
      this.getData(current, this.params.pageSize);
    },
    pageSizeChange(e) {
      this.params.pageSize = e;
      this.getData(1, e);
    },
    formatTime(time) {
      if (time[0] && !time[1]) {
        return `>= ${time[0]}`;
      } else if (!time[0] && time[1]) {
        return `<=${time[1]}`;
      } else if (time[0] && time[1]) {
        return `${time[0]}至${time[1]}`;
      }
    },
    async exportData() {
      let downListByRple = localStorage.getItem("downListByRple")
        ? JSON.parse(localStorage.getItem("downListByRple"))
        : "";
      let maxDownload = downListByRple[43].rowLimited;
      let data = await this.getJwz(1, maxDownload);
      let filterVal = [];
      let tHeader = [];
      this.tableColumn.forEach((item) => {
        tHeader.push(item.title);
        filterVal.push(item.key);
      });
      const resData = formatJson(filterVal, data.records);
      downloadData(tHeader, resData, "境外债");
    },
    //获取时间
    getDate(value, item) {
      this.params[`${item}Start`] = value[0]; // 开始时间
      this.params[`${item}End`] = value[1]; // 结束时间
    },
    //获取境外债类型
    async get_foreign_debt_type_options() {
      let data = await foreign_debt_type_options({});
      this.bondTypeList = data;
    },
    //获取境外债行业类型
    async get_industry_options() {
      let data = await industry_options({});
      this.zstIndustryCodeList = data;
    },
    //跳转详情
    toDetail(id) {
      //存储状态
      sessionStorage.setItem("tabSelect", "offshoreDebt");
      sessionStorage.setItem("OffshoreDebt", JSON.stringify(this.params));
      this.$router.push({
        // path: `/Insight/enterprise/foreignDetail`,
        path: `/Insight/financialMarket/financialProduct/Bond/foreignDetail`,
        query: {
          id: id,
        },
      });
    },
    showIconInfoBox(type, event) {
      const { x, y, offsetX, offsetY } = event;
      this.titleIconInfoText = type == 0 ? "6个月内到期" : "已违约";
      this.showTitleIconInfo = true;
      // this.iconX = x - offsetX - 240;
      // this.iconY = y - offsetY - 50;
      this.iconX = x - offsetX;
      this.iconY = y - offsetY + 30;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";

.foreignDebt {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
}

.foreignDebt {
  .topPart {
    position: relative;
    width: 100%;
    height: 400px;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    border: 1px solid rgba(227, 227, 229, 1);
    padding: 20px 30px;

    .form-item {
      margin-bottom: 20px;
      position: relative;
      @include flex(flex-start);

      .selectBox {
        width: 320px;
        max-height: 500px;
        overflow: auto;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(214, 215, 217, 1);
        padding: 10px;
        position: absolute;
        left: 76px;
        top: 31px;
        z-index: 100;
      }

      .inputBox {
        margin-right: 50px;
        width: 450px;
        @include flex(flex-start);
      }

      .title {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: rgba(102, 102, 102, 1);
        line-height: 19px;
        margin-right: 20px;
        width: 100px;
        word-break: keep-all;
        text-align: right;
      }

      .addIssue {
        position: relative;

        .seletcTypeBox {
          position: absolute;
          left: 0;
          top: 20px;
          height: 40px;
          display: none;
          overflow: hidden;
          background: #fff;
          border: 1px solid rgba(227, 227, 229, 1);
          padding: 10px;
          z-index: 100;
        }

        &:hover .seletcTypeBox {
          display: block;
        }
      }
    }
  }

  .middlePart {
    margin: 20px 0;
  }
}

.breadCrumbs {
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #ff0000;
  margin: 20px 0;
}
.download {
  padding: 10px;
  float: left;
  width: 100%;
  height: 100%;
}
.diyDetail {
  cursor: pointer;
  color: #169bd5;
}
.pageStyle {
  position: relative;
}
.titleIcon {
  width: 25px;
  height: 25px;
  margin: 5px;
  color: #ffffff;
  // display: inline;
  float: left;
  line-height: 25px;
  background: #f28300;
}
.warnIcon {
  width: 25px;
  height: 25px;
  margin: 5px;
  color: #ffffff;
  float: left;
  line-height: 25px;
  background: #ff0000;
}
.titleText {
  margin: 5px;
  width: 180px;
  height: 25px;
  // float: left;
  line-height: 39px;
  text-align: center;
}
.helpInfoBox {
  position: absolute;
  width: 200px;
  height: 100px;
  background: #fff;
  border: 1px solid;
  top: 90px;
  left: 930px;
  padding: 25px;
  p {
    text-align: center;
  }
}
.titleIconInfoBox {
  width: 100px;
  height: 40px;
  padding: 10px;
  left: 0;
  top: 0;
  text-align: center;
  position: fixed;
  background: #fff;
  border: 1px solid;
}
.actualFinanciersFullSlot {
  width: 220px;
  height: 40px;
  display: flex;
  overflow: hidden;
  .infoIcon {
    // background: #169bd5;
    display: flex;
  }
  .infoTitle {
    min-width: 0;
    width: 220px;
    p {
      // max-width: 220px;
      width: 100%;
      line-height: 40px;
      padding: 0 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.helpBox {
  width: 150px;
  height: 80px;
  /* background: red; */
  color: #000;
  /* border: 1px solid; */
  text-align: center;
  padding: 20px 0;
}
</style>
